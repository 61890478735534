import React from 'react';

import classes from "./SearchBar.module.css";

const SearchBar = (props) => {
    const { placeholder, onChange, value} = props;
    
    return (
        <input className={classes.searchBar} placeholder={placeholder} value={value} onChange={onChange} onFocus={props.handleFocus} onBlur={props.handleBlur} />
    )
}

export default SearchBar