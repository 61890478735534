import React, { useMemo, useEffect, Suspense, useState } from 'react'

import { DateTime } from 'luxon';

import classes2 from "./UsersTableLayout.module.css";
import classes from "./TableLayout.module.css";
import Card from "./Card";
import Table from './Table/Table';
import TableButton from './Table/TableButton/TableButton';
import fiiller_rows from './Table/MOCK_DATA_3.json'
import { Await, useLoaderData } from 'react-router-dom';
import { Oval } from 'react-loading-icons';
import BasicTable from './Table/Table';
import { getActivityLog } from '../../Api Methods/Api';
import UseTimeDateFormat from '../hooks/UseTimeDateFormat';
import { useDispatch } from 'react-redux';
import Pill_Widget from '../UI/Widgets/Pill_Widget';

const ActivityTableLayout = ({ HanldeshowAddUserForm, filtering, HandleTableFiltering, apiData, companyName }) => {

  // ---------------------------- loader data ---------------------------------
  const { portalEvents } = useLoaderData();
  useEffect(() => {
    if (portalEvents.isError) {
      return portalEvents.message;
    }
  }, [portalEvents])


  const [portalActivityEvents, setPortalActivityEvents] = useState(portalEvents)
  //---------------------------------------------------------------------------


   //------------------------------- responsible for updating the Activity Page data every 1 mintue ------------------------------------------------------
   const dispatch = useDispatch();
   useEffect(() => {

    // set initial portalActivityEvents on mount
    setPortalActivityEvents(portalEvents);

    const HandlePortalEvents= async () => {
        const data = await getActivityLog(apiData, dispatch)
        setPortalActivityEvents(data)
    }

    // Set up a timer to fetch data every 1 minute
    const timer1 = setInterval(() => {

      HandlePortalEvents();
    }, 60000);
    return () => clearInterval(timer1);
}, []);
//---------------------------------------------------------------------------------------------------------------------
  
  const tablecolumns = [
    {
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
            <h3>Name</h3>
        </div>
    ),
      accessorKey: 'fullName',
      cell: (row) => {
        return (
          <Pill_Widget
            boolean={true}
            PillColor_IfTrue={"#6996f1"}
            PillColor_IfFalse={"#626a67"}
            text_IfTrue={row.row.original.fullName}
            className={classes.PillHeight}
          />
        )
      }
    },
    {
      header: () => (
        <div className={classes.tableTitleText}  title="Click to sort">
            <h3 >Email Address</h3>
        </div>
    ),
      accessorKey: 'email',
      cell: (row) => {
        return (
          <div>
            <p className={classes.tableValueText}>{row.row.original.email}</p>
          </div>
        )
      }
    },
    {
      header: () => (
        <div className={classes.tableTitleText}  title="Click to sort">
            <h3>Last logged in</h3>
        </div>
    ),
      accessorKey: 'logged_in_at',
      cell: (row) => {
        const { date, time } = UseTimeDateFormat(row.row.original.logged_in_at)
        return (
          <>
            <p className={classes.tableValueText}>{date + ", " + time}</p>
          </>
        )
      }
    },
  ];


  const tableData = portalActivityEvents

  return (
    <div className={`${classes.table} ${classes.center} `}>
      <Card card2={classes.card2}>

        <div className={classes["tableCardLayoutUI-wrapper"]}>
          <div className={classes["top-wrapper"]}>
          </div>
          <Suspense fallback={
            <div className={classes["loadingSpinner-container"]}>
              <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
            </div>
          }>
            <Await resolve={tableData}>
              {(loadedtableData) => {
                  // loadedtableData.reverse()
                return (<BasicTable data={loadedtableData} columns={tablecolumns} filtering={filtering} rowClick={false} ShowPagination={true} PageSize={50} paginationOptions={[25, 50, 100]} showFilterButton={false} HandleTableFiltering={HandleTableFiltering} />)

              }}
            </Await>
          </Suspense>
        </div>
      </Card>
    </div>
  )
}

export default ActivityTableLayout;