import React, { useMemo, useEffect, Suspense, useState, useRef } from 'react'

import { DateTime } from 'luxon';

import classes2 from "./UsersTableLayout.module.css";
import classes from "./TableLayout.module.css";
import Card from "./Card";
import Table from './Table/Table';
import TableButton from './Table/TableButton/TableButton';
import fiiller_rows from './Table/MOCK_DATA_3.json'
import { Await, Link, useLoaderData, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loading-icons';
import UseDateFormat from '../hooks/UseDateFormat';

import diableNotificationIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import SettingsCog from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Admin - Settings.svg"
import DropdownList from '../UI/Cards/DropdownList';
import { enableUserNotifications, getUsersDevices } from '../../Api Methods/Api';
import BasicTable from './Table/Table';
import { useDispatch } from 'react-redux';
import useSetCookie from '../hooks/UseSetCookie';
import CustomLink from '../CustomLink/CustomLink';
import { authActions } from '../../store/auth-Slice';

const PortalUsersTableLayout = ({ HanldeshowAddUserForm, filtering, HandleTableFiltering, apiData, companyName }) => {

  // ---------------------------- loader data ---------------------------------
  const { portalUsers } = useLoaderData();

  useEffect(() => {
    if (portalUsers.isError) {
      return portalUsers.message;
    }
  }, [portalUsers])

  //---------------------------------------------------------------------------
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    setUsersData(portalUsers);
  }, [portalUsers])

  // -- Controls the settingCog wheel -------------------------------------- 
  const [openDropdownRowId, setOpenDropdownRowId] = useState(null);

  const handleSettingsClick = (rowId) => {
    // If the clicked dropdown is already open, close it
    if (openDropdownRowId === rowId) {
      setOpenDropdownRowId(null);
    } else {
      // Otherwise, open the clicked dropdown
      setOpenDropdownRowId(rowId);
    }
  };
  // -----------------------------------------------------------------------


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleUipiodOnClick = async (apiData, UserID, fullName, companyName) => {
    sessionStorage.setItem('ClickedUserID', UserID);
    sessionStorage.setItem('ClickedUserFullName', fullName);

    dispatch(authActions.setClickedUserDetails({ UserID, fullName }))

    navigate("UserDevices");
    // console.log(apiData, UserID, companyName);
    // const response = await getUsersDevices(apiData, UserID, companyName);
  }

  //------------------ handling the closing of the setting cog wheel drop down by clicking else where -----------------------------------

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown menu, so close it
        setOpenDropdownRowId(null);
      }
    };

    // Add event listener to listen for clicks outside the dropdown menu
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Cleanup: Remove event listener when component unmounts
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  //------------------------------------------------------------------------
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState()

  // ---------- carrys out clicked action in dropdown
  const UserActionHandler = (userAction, userId, notifications) => {
    switch (userAction) {
      case 'Disable Notifications':
      case 'Enable Notifications':
        const EnableUserNotifications = async (apiData, userId, notifications) => {

          const enableNotification = notifications == "yes" ? "no" : "yes"
          const responseData = await enableUserNotifications(apiData, userId, enableNotification, dispatch)

          if (responseData) {
            const data = await usersData;

            // Find the row in the data array and update its name
            const updatedData = [...data]; // Create a shallow copy of the original array

            for (let i = 0; i < updatedData.length; i++) {
              if (updatedData[i].id === userId) {
                updatedData[i] = { ...updatedData[i], notifications: enableNotification };
                break; // Exit the loop once the desired object is found and updated
              }
            }
            
            // Update the state with the new data
            setUsersData(updatedData);

          }
          setOpenDropdownRowId(null);
        }
        EnableUserNotifications(apiData, userId, notifications);
        break;

      default:
        console.warn('Invalid option:', userAction);
    }
  }
  const tablecolumns = [
    {
      header: 'Full Name',
      accessorKey: 'fullName',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(apiData, row.row.original.id, row.row.original.fullName, companyName)} style={{ cursor: "pointer", width: "fit-content" }}>
            {row.row.original.fullName}
          </div>
        )
      }
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (row) => {


        return (
          <div onClick={() => HandleUipiodOnClick(apiData, row.row.original.id, row.row.original.fullName, companyName)} style={{ cursor: "pointer", width: "fit-content" }}>
            {row.row.original.email}
          </div>
        )
      }
    },
    {
      header: 'Last Logged In',
      accessorKey: 'lastLoggedIn',
      cell: (row) => {


        return (
          <div onClick={() => HandleUipiodOnClick(apiData, row.row.original.id, row.row.original.fullName, companyName)} style={{ cursor: "pointer", width: "fit-content" }}>
            {UseDateFormat(row.row.original.lastLoggedIn)}
          </div>
        )
      }
    },
    {
      header: 'Notifications Enabled',
      accessorKey: 'notifications',
      cell: (row) => {
        const notifications = row.row.original.notifications;
        return (
          <div onClick={() => HandleUipiodOnClick(apiData, row.row.original.id, row.row.original.fullName, companyName)} style={{ cursor: "pointer", width: "fit-content" }}>
            {notifications != "" ? notifications : "No"}
          </div>
        )
      }
    },
    {
      id: 'actions',
      cell: (row) => {
        const items =
          [
            {
              image: diableNotificationIcon,
              title: row.row.original.notifications ? "Disable Notifications" : "Enable Notifications"
            }]
        const ifSettingsCogIsClicked = openDropdownRowId === row.row.id
        return (
          <DropdownList
            item={row.row.id}
            icon={SettingsCog}
            onClick={handleSettingsClick}
            ifSettingsCogIsClicked={ifSettingsCogIsClicked}
            ref={dropdownRef}
            title={"Actions"}
            className={classes.container}
            onUserActionClick={(userAction) =>
              UserActionHandler(userAction, row.row.original.id, row.row.original.notifications)}
            listItems={items}
          />
        )
      }
    }
  ];

  let tableData = usersData || portalUsers;

  const fiillerRows = useMemo(() => fiiller_rows, []);
  // if (Sites.length <= 10) {
  //   tableData = Sites.concat(fiillerRows);
  // }

  return (
    <div className={`${classes.table} ${classes.center} `}>
      <Card card2={classes.card2}>

        <div className={classes["tableCardLayoutUI-wrapper"]}>
          <div className={classes["top-wrapper"]}>
            <h1>Portal Users</h1>
          </div>
          <Suspense fallback={
            <div className={classes["loadingSpinner-container"]}>
              <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
            </div>
          }>
            <Await resolve={tableData}>
              {(loadedtableData) =>
                <BasicTable data={loadedtableData} columns={tablecolumns} filtering={filtering} rowClick={false} ShowPagination={true} PageSize={50} paginationOptions={[25, 50, 100]} showFilterButton={true} HandleTableFiltering={HandleTableFiltering} />
              }
            </Await>
          </Suspense>
        </div>
      </Card>
    </div>
  )
}

export default PortalUsersTableLayout;