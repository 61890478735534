import React from 'react'
import classes from './Button2.module.css';

const Button2 = ({ onClick, children, style, propsclassName, disabled, type, onMouseEnter, onMouseLeave, img }) => {
  return (
    <button className={`${classes["tableButton-container"]} ${propsclassName || null}`} style={style} disabled={disabled} type={type} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
      {img && <img className={classes.image} src={img} />}
    </button >
  )
}

export default Button2;
