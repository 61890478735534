import React, { useState } from 'react';
import classes from './Tooltip.module.css'; // Import the CSS for styling

const Tooltip = ({ text, children }) => {
    const [visible, setVisible] = useState(false);

    return (
        <div
            className={classes.tooltip}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            {children}
            {visible && (
                <div className={classes.tooltiptext}>
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
