import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classes from "./Breadcrumbs.module.css";

import CustomLink from "../CustomLink/CustomLink";

function formatBreadCrumb(string) {
  return string.replace(/^\d+%20-%20/, '');
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const CheckStringIFUUID = (str) => {
  if (str.length > 15) {
    return true
  }

  return false;
};

const Breadcrumbs = () => {
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Create a media query
    const mediaQuery = window.matchMedia('(max-width: 500px)');

    // Define a function to handle changes in the media query
    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Add event listener to the media query
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    // Initial check for the media query
    setIsMobile(mediaQuery.matches);

    // Clean up by removing the event listener
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []); // Run only once on component mount


  let currentLink = '';
  let dasboardCrumbPresent = false;
  const crumbs =
    location.pathname.split("/")
      .filter((crumb, index) => index === 0 || crumb !== "")
      .map((crumb, index, array) => {
        //-------------- Creating the url path for each breadcrumb link ----------------------------------------------------------------------------
        // each breadcrumb link is the current crumb plus any crumbs added to currentLink already added . e.g. "/" ->  "/1"  ->  "/1/Sites" -> "/1/Sites/1/Users"     the forward slach is for root, the first number is the id of the group selected, the sites page displays all the sites for the selected group, the second number is the select site, and users is the users table page showing all the users for that site.

        if (crumb === "") {
          return null;
        }
        if (CheckStringIFUUID(crumb)) {
          return null;
        }

        // If the current value is "Siteview" and it's not the last element
        if (crumb === "SiteView" && index !== array.length - 1) {
          // Concatenate the next index value which is the UUID onto "Siteview"

          const Crumb = crumb + "/" + array[index + 1];
          currentLink += `/${Crumb}`;
        }
        else {
          currentLink += `/${crumb}`;
        }


        //------------------------------------------------------------------------------------------------------------

        //------------------- removing any intergers from current crumb -------------------------------------------------------------
        // this is removing any intergers that may be in the crumbs to avoid them appearing in the breadcrumb links.
        const isInt = /^\d+$/.test(crumb);
        if (isInt) {

          // Exclude integer crumbs from the list
          return null;
        }
        //------------------------------------------------------------------------------------------------------------

        //----------------------- if only crumb is empty then replace with the text Dashboard ----------------------------------------------------------

        if (crumb === "portal") {
          crumb = "dashboard";
        }

        if (crumb === "AllSites") {
          crumb = "All Sites";
        }

        if (crumb === "PortalActivity") {
          crumb = "Portal Activity";
        }

        if (crumb === "UserManagement") {
          crumb = "User Management";
        }

        if (crumb === "UserSites") {
          crumb = "User Sites";
        }

        if (crumb === "SiteActivity"){
          crumb = "Site Activity"
        }

        if (crumb === "SiteView") {
          crumb = "Site View";
        }


        //------------------------------------------------------------------------------------------------------------


        // formating the crumb to removing any spaces
        const formattedString = formatBreadCrumb(crumb);

        // removing any forward slashes / or dashes -
        const formattedcrumb = formattedString.replace(/[/\-]/g, ' ');

        let Crumb = capitalizeFirstLetter(formattedcrumb);

        // making the last crumb in the breadcrumb styled differently to show current page.

        let isLastCrumb;
        if (crumb === "Site View") {
          isLastCrumb = index
        } else {
          isLastCrumb = index === array.length - 1;
        }
        return (
          <div className={classes.crumb} key={crumb}>
            <CustomLink
              to={currentLink.replace(/\/+/g, "/")}
              className={`${classes["button-container"]} ${isLastCrumb ? classes.active : ""
                }`}
            >
            
              {/* 
                commented out simpler breadCrumb in css --> @media screen and (max-width: 500px) {... 
              
                  aswell as line below
              {Crumb} {!isLastCrumb && isMobile && " / "} */}

              {Crumb}

            </CustomLink>
          </div>
        );
      });

  return (
    <div className={classes["breadcrumb-container"]}>
      {crumbs}
    </div>
  )
}

export default Breadcrumbs