import React from 'react'

const DropdownItem = (props) => {
    console.count("DropdownItem RUNNING");
    return (
        <div className={props.className} onClick={() => props.onClick(props.item.title)}>
            <img alt={"image"} className={props.img} src={props.item.image} />
            <button style={{ border: 0 }} >
                <h2>{props.item.title}</h2>
            </button>
        </div >
    )
}

export default DropdownItem