import React, { useEffect, useState, useMemo, useCallback } from 'react'

import classes from './Stats2.module.css'


import usersIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Stats/user.png'
import OnlineIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Stats/online.png'
import OfflineIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Stats/offline.png'
import SpinnerIcon from '../../../assets/portalAssets/spinner.png'

import TimerIndicator from './Stats2/TimerIndicator'

import { Oval } from 'react-loading-icons'


const Stats2 = ({ title, value, status, isLoadingStats }, props) => {

    const [isMoblie, setIsMoblie] = useState(false)
    const [cssClass, setCssClass] = useState({ stroke: "var(--Primary-Color2)", height: 47.5, speed: 1.5 });

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 430px)'); // Define your media query here

        // Function to handle changes in the media query
        const handleIsMobile = () => {
            if (window.innerWidth <= 430) {
                setIsMoblie(true)

            } else {
                setIsMoblie(false)
            }
        };

        // Initial check for if screen has been resized
        handleIsMobile(mediaQuery);

        // Add event listener for changes in the media query
        mediaQuery.addListener(handleIsMobile);

        // Clean up by removing event listener when component unmounts
        return () => {
            mediaQuery.removeListener(handleIsMobile);
        };
    }, []);

    useEffect(() => {
        if (!isMoblie) {
            setCssClass({ stroke: "var(--Primary-Color)", height: 47.5, speed: 1.5 });
        } else if (isMoblie) {
            setCssClass({ stroke: "var(--Primary-Color)", height: 30, speed: 1.5 });
        }
    }, [isMoblie]);


    let specialClasses = null;
    let icon;

    if (title === 'Total users') {
        specialClasses = classes.users;
        icon = usersIcon;
    }
    if (title === 'Online sites') {
        specialClasses = classes.devicesOnline;
        icon = OnlineIcon;
    }
    if (title === 'Offline sites') {
        specialClasses = classes.devicesOffline;
        icon = OfflineIcon;
    }

    const cssClasses = `${classes['Top-left']} ${specialClasses}`;

    return (
        <div className={`${classes.container} ${props.container}`}>

            <div className={classes["Top"]}>
                <h3>{title}</h3>
                <TimerIndicator className={classes["TimeStatus-component"]} status={status} />
            </div>

            <div className={classes["Bottom"]}>

                <div className={cssClasses}>
                    {isLoadingStats ?
                        <Oval stroke={cssClass.stroke} height={cssClass.height} speed={cssClass.speed} /> :
                        <h1>{value.toLocaleString()}</h1>}
                </div>
                <img className={classes.icon} alt={title + " icon"} src={icon} />
            </div>
        </div>
    )
}

export default Stats2;