import React, { useEffect, useState } from "react";
import classes from './Card.module.css';

import IPIOCC_Icon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Online.svg"

const Card = (props, { key }) => {
    const delayFactor = 0.05; // Adjust this factor to control the delay increase
    const delay = props.index * delayFactor;

    return (
        <div key={key || null} style={{ width: props.cardWidth, height: props.cardHeight, animationDelay: `${delay}s` }} className={`${classes.card} ${props.className} `} onClick={props.onClick}>
            <div style={{ display: "flex" }}>
                {/* <div style={{ height: "15px", width: "0%", backgroundColor: props.color && props.color == "CC" ? "#05aec8" : "lightgrey", borderRadius: "12px 12px 0px 0px", opacity: props.isOnline == "NO" && "37%" }} /> */}
 
                <div style={{ height: "12px", width: "80%", backgroundColor: props.color && props.color == "CC" ? "#6aa1f4" : "lightgrey", borderRadius: "12px 0px 0px 0px", opacity: props.isOnline == "NO" && "100%" }} />
                <div style={{ height: "12px", width: "20%", backgroundColor: props.isOnline ? props.isOnline == 'YES' ? "var(--Online-Colour)" : "var(--Offline-Colour)" : "lightgrey", borderRadius: "0px 12px 0px 0px", opacity: props.isOnline == "NO" && "100%" }} />

            </div>
            {props.children}
        </div>
    );
}

export default Card;
