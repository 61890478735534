import React from "react";

const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

const  UseDateFormat = (passedDate) => {
        const date = new Date(passedDate);  // Create a javascript data object
        const day = date.getUTCDate();  //extracts the day in UTC time, returns a int
        const month = date.toLocaleString('en-US', { month: 'long' });  // toLocaleString formats the month component within the date object to human readable format. en-US is used to set it to US English, the second argument, is set to long as we want the full month. returns a string.
        const year = date.getUTCFullYear(); //returns a 4 digit int reprsenting the year
        const suffix = getDaySuffix(day);
        return `${day}${suffix} ${month} ${year}`;
    }

export default UseDateFormat;