import EditPinInput from "../Input/EditPinInput";
import ComponentCard from "../../../../Cards/ComponentCard/ComponentCard"
import classes from "./PinInputList.module.css";

const EditPinInputList = ({ InputPins, polarity, HandleShowEditPinForm, setPinDetails }) => {

  return (
    <ComponentCard>
      <div className={classes["pin-outputs_list"]}>
        <ul>
          {InputPins.map((inputPin, index) =>
            <li key={index}>
              <EditPinInput key={index} InputPin={inputPin} polarity={polarity} HandleShowEditPinForm={HandleShowEditPinForm} setPinDetails={setPinDetails}  />
            </li>
          )}
        </ul>
      </div>
    </ComponentCard>
  )
}

export default EditPinInputList;
