import classes from "./Pill_Widget.module.css";

const Pill_Widget = ({
  boolean,
  PillColor_IfTrue,
  PillColor_IfFalse,
  textColour_IfTrue,
  textColour_IfFalse,
  text_IfTrue,
  text_IfFalse,
  height,
  className, // Add className prop
}) => {
  return (
    <div
      className={`${classes["Pill_Widget"]} ${className || ""}`} // Merge internal and external classes
      style={{
        backgroundColor: boolean ? PillColor_IfTrue : PillColor_IfFalse,
        color: boolean ? textColour_IfTrue : textColour_IfFalse,
        fontWeight: "600",
        height: height,
      }}
    >
      {boolean ? text_IfTrue : text_IfFalse}
    </div>
  );
};

export default Pill_Widget;
