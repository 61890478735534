import React, { useState } from 'react'
import Button1 from '../../../Button/Button1';

import classes from "./List.module.css"

const List = ({ message, listItems, pinName, prevPage, modalpageIndex, callback }) => {
    const [pinsSelected, setPinsSelected] = useState([])

    //only modal page 2 needs to return a array
    const HandleReturnArray = (item) => {

        const DoesItemAlreadyExist = pinsSelected.find(Pin => Pin.pinName === item.pinName);
        if (DoesItemAlreadyExist) {
            const updatedPinsSelected = pinsSelected.filter(Pin => Pin.pinName !== item.pinName);
            setPinsSelected(updatedPinsSelected);
            if (updatedPinsSelected.length != 0) {
                callback(updatedPinsSelected);
            }
        } else {

            // add item and pass array to callback
            const PinsSelected = addItem(item)
            callback(PinsSelected);
        }

    }

    const addItem = (item, array) => {

        // Create a copy of the current array
        const newArray = [...pinsSelected];
        // Push the new item to the copy
        newArray.push(item);
        // Set the state with the new array
        setPinsSelected(newArray);
        return newArray;
    };

    const HandleCallBack = (item) => {
        callback(item);
    }


    const HandlePrePage = () => {

        if (prevPage) {

            if (modalpageIndex == 1 || modalpageIndex == 3 || modalpageIndex == 4) {
                prevPage(true);
            }
        }


    }
    const FilterArray = () => {

        if (pinName && pinName[0]) {
            return listItems.filter(item => item.pinName != pinName[0].pinName);
        } else {
            return listItems
        }
    }


    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", position: "relative", width: "100%" }}>

            {/* Back button */}
            {modalpageIndex == 1 && prevPage && <div className={classes.backButton} onClick={() => prevPage(false)} >{"<"}</div>}

            {message && message !== "" && <h4 style={{ fontWeight: "400" }} >{message}</h4>}
            <ul style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
                {FilterArray().map((item, index) => {
                    return (
                        <Button1 style={{ backgroundColor: pinsSelected.find(pinName => pinName === item) && "var(--Primary-Color)", color: pinsSelected.find(pinName => pinName === item) && "white", border: pinsSelected.find(pinName => pinName === item) && "2px solid lightblue" }}
                            onClick={() => { HandleReturnArray(item); HandlePrePage(); }} key={index}>
                            {item.pinName || item}
                        </Button1>

                    )
                })}
            </ul>
        </div >
    )
}

export default List;