import React from 'react';

import classes from './FormButton.module.css'

const FormButton = (props) => {
  return (
    <button
      type={props.type || 'button'}
      className={`${classes["form-button"]} ${props.className}`}
      style={props.style}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children} {props.value}
    </button>
  );
};

export default FormButton;