import { id } from "date-fns/locale";
import { useEffect, useRef, useState } from "react"
import classes from './UserManagementList.module.css'
import { useDispatch, useSelector } from "react-redux";
import { Await, useNavigate } from "react-router-dom";
import Pill_Widget from "../../components/UI/Widgets/Pill_Widget";
import UseTimeDateFormat from "../../components/hooks/UseTimeDateFormat";
import ListCard from "../../components/GridList/ListCard";
import BasicTable from "../../components/Table/Table/Table";
import { authActions } from "../../store/auth-Slice";
import DeleteIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/trash.png"
import SettingsIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/settings.png"
import Modal from "../../components/Modal/Modal";
import { deleteGroupUser, enableUserNotifications, switchInstallerMode } from "../../Api Methods/Api";
import Button1 from "../../components/UI/Button/Button1";
import installerActive from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/installer-on.png"
import installerInactive from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/installer off.png"
import ActiveNotificationBellIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - On.svg"
import InactiveNotificationBellIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - Off.svg"
import DropdownList from "../../components/UI/Cards/DropdownList";

const UserList = ({data, apiData, isGroup, update, setUpdate}) => {

    const [users, setUsers] = useState((data));
    console.log(data);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const client = useSelector(state => state.auth.client);
    const ApiData = {
        token,
        client
    }

    const navigateToUserSites = (userID, fullName) => {
        sessionStorage.setItem('ClickedUserID', userID);
        sessionStorage.setItem('ClickedUserFullName', fullName);

        dispatch(authActions.setClickedUserDetails({ userID, fullName }))

        navigate("/Dashboard/Users/UserSites");
    }

    const [modalTitle, setModalTitle] = useState("title")
    const [modalComponent, setModalComponent] = useState()

    const ModalDialog = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();
    }


    const HandleDelete = (userID) => {
        console.log(userID)
        setModalTitle("Remove user?")
        setModalComponent(
            <div>
              <div style={{ textAlign: "center" }}>
                <h2>Are you sure you want to remove this user?</h2>
              </div>
              <br />
              <div style={{ 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                gap: "10px" // Adds space between buttons
              }}>
                <Button1 onClick={HandleCloseModal}>No, cancel</Button1>
                <Button1 onClick={() => DeleteUser(userID)}>Yes, I'm sure</Button1>
              </div>
            </div>
          );

          HandleShowModal();
    }

    const DeleteUser = async (userID) => {
        // do API
        console.log(userID)
        const response = await deleteGroupUser(ApiData, userID, dispatch);
        if (response){
            console.log(response)
            setUpdate(update+1);
            HandleCloseModal();
        }
    }

    const HandleUserAction = async (userAction, userID, notifications, portalAccess, row) => {
        switch (userAction) {
            case "Enable Installer Mode":
                const enableInstallerMode = async (apiData, userID) => {
                    const response = await switchInstallerMode(apiData, userID, "INSTALLER")
                    if (response) {
                        row.portalAccess = "INSTALLER"
                        setOpenDropdownRowId(null);
                    }
                }

                enableInstallerMode(apiData, userID)
                break;
            case "Disable Installer Mode":
                const disableInstallerMode = async (apiData, userID) => {
                    const response = await switchInstallerMode(apiData, userID, "")
                    if (response) {
                        row.portalAccess = ""
                        setOpenDropdownRowId(null);
                    }
                }

                disableInstallerMode(apiData, userID)
                break;
            case "Disable Notifications":
                const DisableUserNotifications = async (apiData, userId, notifications) => {
                    const disableNotifications = notifications == "no"
                    const responseData = await enableUserNotifications(apiData, userId, disableNotifications, dispatch)
                    if (responseData) {
                        row.notifications = "no"
                        setOpenDropdownRowId(null);
                    }
                }

                DisableUserNotifications(apiData, userID, notifications);
                break;
            case "Enable Notifications":
                const EnableUserNotifications = async (apiData, userId, notifications) => {
                    const enableNotification = notifications == "yes"
                    const responseData = await enableUserNotifications(apiData, userId, enableNotification, dispatch)
                    if (responseData) {
                        row.notifications = "yes"
                        setOpenDropdownRowId(null);
                    }
                }

                EnableUserNotifications(apiData, userID, notifications);
                break;
            default:
                console.alert('Invalid option:', userAction);
        }
    }

    // -- Controls the settingCog wheel -------------------------------------- 
    const [openDropdownRowId, setOpenDropdownRowId] = useState(null);

    const handleSettingsClick = (rowId) => {
        // If the clicked dropdown is already open, close it
        if (openDropdownRowId === rowId) {
        setOpenDropdownRowId(null);
        } else {
        // Otherwise, open the clicked dropdown
        setOpenDropdownRowId(rowId);
        }
    };

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                // Clicked outside the dropdown menu, so close it
                setOpenDropdownRowId(null);
            }
        };

        // Add event listener to listen for clicks outside the dropdown menu
        document.addEventListener('click', handleClickOutside);

        return () => {
        // Cleanup: Remove event listener when component unmounts
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    // -----------------------------------------------------------------------
    
    const tableColumns = [
        {
            id: 'name', 
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Name</h3>
                </div>
            ),
            accessorKey: 'fullName',
            cell: (row) => {
                return (
                    <div onClick={() => navigateToUserSites(row.row.original.id, row.row.original.fullName)} className={classes.tableValueText}>
                        <Pill_Widget
                            boolean={true}
                            PillColor_IfTrue={"#6996f1"}
                            PillColor_IfFalse={"#626a67"}
                            text_IfTrue={row.row.original.fullName}
                            className={classes.PillHeight}
                        />
                    </div>
                )
            }
        },
        {
            id: 'email', 
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Email Address</h3>
                </div>
            ),
            accessorKey: 'email',
            cell: (row) => {
                return (
                    <div onClick={() => navigateToUserSites(row.row.original.id, row.row.original.fullName)} className={classes.tableValueText}>
                        {row.row.original.email}
                    </div>
                )
            }
        },
        {
            id: 'lastLoggedin', 
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Last logged in</h3>
                </div>
            ),
            accessorKey: 'lastLoggedIn',
            cell: (row) => {
                const lastLoggedIn = row.row.original.lastLoggedIn;
                const displayValue = lastLoggedIn
                ? UseTimeDateFormat(lastLoggedIn)
                : { date: "Never logged in", time: "" };

                const { date, time } = displayValue;
                return (
                    <>
                        <p className={classes.tableTitleText}>{date + ", " + time}</p>
                    </>
                )
            },
        },
        {
            id: 'portalAccess',
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                  <h3>Admin access</h3>
              </div>
            ),
            accessorKey: 'portalAccess',
            cell: (row) => {
              return (
                <div>
                    {row.row.original.portalAccess && row.row.original.portalAccess.trim() !== "" && (
                        <Pill_Widget
                            boolean={true}
                            PillColor_IfTrue={"#FFB38e"}
                            PillColor_IfFalse={"#626a67"}
                            text_IfTrue={"Yes"}
                            className={classes.PillHeight}
                        />
                    )}     
                </div>
              )
            }
          },
          {
            //Settings cog
            id: 'delete',
            cell: (row) => {
                return (
                    <div className={classes["icons-container"]}>
                        <img className={classes.Icon} src={DeleteIcon} 
                            onClick={() => HandleDelete(row.row.original.userID)} 
                        />
                    </div>
                )
            },
            hidden: !isGroup,
          },
          {
            id: 'userSettings', 
            cell: (row) => {
                let items = [
                    {
                        image:
                            row.row.original.notifications == "yes"
                                ? InactiveNotificationBellIcon
                                : ActiveNotificationBellIcon,
                        title:
                            row.row.original.notifications == "yes"
                                ? "Disable Notifications"
                                : "Enable Notifications",
                    },
                    {
                        image: 
                            row.row.original.portalAccess != "" && row.row.original.portalAccess != null
                                ? installerInactive
                                : installerActive,
                        title: 
                            row.row.original.portalAccess != "" && row.row.original.portalAccess != null
                                ? "Disable Installer Mode"
                                : "Enable Installer Mode",
            
                    }
                ];
                
                const ifSettingsCogIsClicked = openDropdownRowId === row.row.id
                return <DropdownList
                    item={row.row.id}
                    icon={SettingsIcon}
                    onClick={handleSettingsClick}
                    ifSettingsCogIsClicked={ifSettingsCogIsClicked}
                    ref={dropdownRef}
                    title={"Actions"}
                    className={classes.container}
                    onUserActionClick={(userAction) => 
                        HandleUserAction(userAction, row.row.original.id, row.row.original.notifications, row.row.original.portalAccess, row.row.original)
                    }
                    listItems={items}
                />
            },
            hidden: isGroup
          },
      ];

    const filteredTableColumns = tableColumns.filter(column => !column.hidden);

    return (
        <>
        <Modal ref={ModalDialog} title={modalTitle} modalName={"Site View"} HandleShowModal={HandleShowModal}>
        {modalComponent}
        </Modal>
        <div className={`${classes.table} ${classes.center} `}>
            <ListCard secondaryClassName={classes["Card-container"]}>
                <div className={classes["tableCardLayoutUI-wrapper"]} style={{ padding: "0px 10px 0px 10px" }}>
                    <Await resolve={data}>
                        {(loadedTableData) => {
                            return (
                                <>
                                    <BasicTable
                                        data={loadedTableData}
                                        columns={filteredTableColumns}
                                        filtering={""}
                                        rowClick={false}
                                        ShowPagination={false}
                                        PageSize={5000}
                                        paginationOptions={[25, 50, 100]}
                                        showFilterButton={false}
                                    />
                                </>
                            )
                        }}
                    </Await>
                </div>
            </ListCard>
        </div>
        </>
    )
}

export default UserList;