import { configureStore } from "@reduxjs/toolkit";

import authReducer from './auth-Slice';
import uiReducer from './ui-slice';
import serviceReducer from "./service-Slice";

const store = configureStore({
    reducer: { auth: authReducer, ui: uiReducer, service: serviceReducer },
})

export default store;