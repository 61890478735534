import { Outlet } from "react-router-dom";
import SimpleBreadcrumb from "../../components/Simple Breadcrumb/SimpleBreadcrumb";

import classes from "./UserManagementRoot.module.css"

function UserManagementRoot() {
    // console.log("AUTH-ROOT RUNNING")
    return (
        <div className={classes.userManagementRoot}>
            <div className={classes['breadcrumb-Wrapper']}>
                {/* <SimpleBreadcrumb /> */}
            </div>
            <Outlet />
        </div>
    )
}

export default UserManagementRoot;   