import React, { useState, useEffect, Suspense } from 'react'

import classes from "./DeviceManager.module.css";

import FilterSearch from "../PowerSearch/PowerSearch";
import DeviceLoadingGrid from '../GridList/DeviceLoadingGrid';
import DevicesGrid from '../GridList/DevicesGrid';
import { Await, useNavigate } from 'react-router-dom';
import CategoryFilter from '../PowerSearch/CategoryFilter';
import SortDevices from '../PowerSearch/SortDevices';
import GetAllDevices from '../../Pages/Dashboard/GetAllDevices';
import ExportExcel from '../ExcelExport/ExcelExport';
import UseDateFormat from '../hooks/UseDateFormat';
import PowerSearch from '../PowerSearch/PowerSearch';
import GetPagedDevices from '../../Pages/Dashboard/GetPagedDevices';
import GetAllPagedDevices from '../../Pages/Dashboard/GetAllPagedDevices';
import { useDispatch, useSelector } from 'react-redux';
import DataManager from '../../hooks/DataManager';
import Button1 from '../UI/Button/Button1';
import { he } from 'date-fns/locale';
import gridIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/grid.png'
import listIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/list.png'
import { addSitesToGroup } from '../../Api Methods/Api';
import UsersTableLayout from '../Table/UsersTableLayout';
import DevicesList from '../GridList/DevicesList';
import { Tooltip, Whisper } from 'rsuite';

const DeviceManager = ({ client, token, companyName, aiQuery, getAllDevices }) => {
    const dataManager = new DataManager();
    let [filteredResults, setFilteredResults] = useState([]);
    let [maxPagedFetches, setMaxPagedFetches] = useState(2);
    getAllDevices = 1;
    dataManager.setItem('getAllDevices', getAllDevices);
    const groups = useSelector(state => state.auth.groups);
    const parsedGroups = JSON.parse(groups);
    const groupBreadCrumb = parsedGroups.find((group) => group.name === companyName)
    const groupPath = groupBreadCrumb.group_path
    const GroupNamesArray = groupPath.split(">");
    const hasCachedSites = dataManager.getItem('filteredResults')?.length > 0
    const [isloading, setIsLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState(dataManager.getItem('searchQuery'));
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [sortBy, setSortBy] = useState('all')
    const [isGetAllDevicesPressed, setIsGetAllDevicesPressed] = useState(dataManager.getItem('allSitesPressed'));
    const [pageFetchedIndex, setPageFetchedIndex] = useState(dataManager.getItem('pageIndex'));
    const [pageSize, setPageSize] = useState(dataManager.getItem('pageSize'));
    const [totalDevices, setTotalDevices] = useState(0);
    const [totalPages, setTotalPages] = useState(null);
    const navigate = useNavigate();
    const _client = useSelector(state => state.auth.client);
    const _token = useSelector(state => state.auth.token);
    const apiData = {
        _token,
        _client
    }

    const [update, setUpdate] = useState(0)

    const [viewType, setViewType] = useState(dataManager.getItem('viewType'))

    const changeViewType = () => {
        setViewType(viewType === 0 ? 1 : 0)
        dataManager.setItem('viewType', viewType === 0 ? 1 : 0)
    }

    const tooltip = (
        <Tooltip>
          {viewType === 0 ? "Switch to List View" : "Switch to Grid View"}
        </Tooltip>
    );
    
    useEffect(() => {
        //update the current data as a change has been made from devicesList
        // >0 to avoid a double load on mount
        console.log("here")
        if (update > 0){
            
        }
    }, [update])

    const handleFilterLogic = async (data, searchQuery, selectedCategories, sortBy) => {
        function hasDevicesKey(data) {
            return Array.isArray(data) && data.every(entry => entry.hasOwnProperty('devices'));
        }

        let flattenedDataDevices;

        if (hasDevicesKey(data)) {
            flattenedDataDevices = data.flatMap(entry => entry.devices);
        } else {
            flattenedDataDevices = data
        }

        let sortedData = flattenedDataDevices;
        let preFilteredData;
        sortedData != undefined ? preFilteredData = sortedData : preFilteredData = data;

        let flattenedData;

        if (hasDevicesKey(preFilteredData)) {
            flattenedData = preFilteredData.flatMap(entry => entry.devices);
        } else {
            flattenedData = preFilteredData
        }

        setFilteredResults(flattenedData);
    };
    //---------------------------------------------------------------------------------------------------------------------

    //  ------------ used to populate the search if users uses breadcrumb to go back to Dashboard ------------------
    useEffect(() => {
        setFilteredResults(dataManager.getItem('filteredResults'));
        setPageFetchedIndex(dataManager.getItem('pageIndex'));
        setTotalDevices(dataManager.getItem('totalDevices'));
        setTotalPages(dataManager.getItem('totalPages'));
    }, [aiQuery])

    const HandleSaveSearchQuery = () => {
        sessionStorage.setItem('SavedSearchFilters', JSON.stringify(categoryFilter));
        sessionStorage.setItem("SavedSearchSortOrder", JSON.stringify(sortBy));
        dataManager.setItem('filteredResults', filteredResults);
        dataManager.setItem('totalPages', totalPages);
        dataManager.setItem('totalDevices', totalDevices);
    }

    useEffect(() => {
        const SaveSearchFilters = JSON.parse(sessionStorage.getItem('SavedSearchFilters'));
        const SavedSortBy = JSON.parse(sessionStorage.getItem("SavedSearchSortOrder"))
        SaveSearchFilters != null && setCategoryFilter(SaveSearchFilters);
        SavedSortBy != null && setSortBy(SavedSortBy);

        if (getAllDevices == 1) {
            if (!hasCachedSites) {
                setIsGetAllDevicesPressed(true);
                dataManager.setItem('allSitesPressed', true);
            }
        }
    }, [getAllDevices])

    useEffect(() => {
        dataManager.setItem('filteredResults', filteredResults)
        dataManager.setItem('deviceUUIDs', filteredResults.map(device => device.uuid)); //UUIDs to observe for pinStates
    }, [filteredResults])

    const exportData = filteredResults?.map((site) => {

        return {
            "Tag": site.tag,
            "UIPIOID": site.key,
            "Serial Number": site.subGroup,
            "IP Address": site.ip,
            "Firmware": site.firmware,
            "Status": site.status,
            "Initial Activation": UseDateFormat(site.created),
            "Owned by": site.name,
        }
    })

    useEffect(() => {
        console.log("Called")
        if (dataManager.getItem('allSitesPressed')) {
            <GetAllPagedDevices
                searchQuery={searchQuery}
                setIsLoading={setIsLoading}
                handleFilterLogic={handleFilterLogic}
                categoryFilter={categoryFilter}
                sortBy={sortBy}
                setSearchQuery={setSearchQuery}
                setIsGetAllDevicesPressed={setIsGetAllDevicesPressed}
                isGetAllDevicesPressed={isGetAllDevicesPressed}
                filteredResults={filteredResults}
                setFilteredResults={setFilteredResults}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageNumber={pageFetchedIndex}
                setPageNumber={setPageFetchedIndex}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                setTotalDevices={setTotalDevices}
                totalDevices={totalDevices}
                getAllDevices={getAllDevices}
            />;
        }
    }, [pageFetchedIndex])

    const [groupMode, setGroupMode] = useState(dataManager.getItem('isGroupMode'));
    const dispatch = useDispatch();

    const AddSitesToGroup = async () => {

        //show loading overlay? 

        let body = {
            groupID: dataManager.getItem('groupClickedID'),
            groupName: dataManager.getItem('groupClicked'),
            groupDevices: dataManager.getItem('selectedSites'),
        }

        console.log(body);

        const response = await addSitesToGroup(apiData, body, dispatch);

        if (response) {
            console.log(response)
            dataManager.setItem('isGroupMode', false);
            dataManager.setSelectedSites([]);
            setGroupMode(false);
            navigate('/Dashboard/Groups/GroupSites')
        } else {
            //error

        }
    }

    const cancel = () => {
        setGroupMode(false);
        dataManager.setItem('isGroupMode', false);
        dataManager.setSelectedSites([]);
        navigate('/Dashboard/Groups/GroupSites')
    }

    return (
        <div className={classes.DeviceManagerPage}>
            <div className={classes.searchContainer}>
                {/* Search Bar */}
                <PowerSearch
                    placeholder={"Search Site..."}
                    searchQuery={searchQuery}
                    categoryFilter={categoryFilter}
                    setSearchQuery={setSearchQuery}
                    setIsLoading={setIsLoading}
                    setFilteredResults={setFilteredResults}
                    handleFilterLogic={handleFilterLogic}
                    categories={["Online", "Offline", "NoTag", "Favorited", "Not Favorited"]}
                    isGetAllDevicesPressed={isGetAllDevicesPressed}
                    setIsGetAllDevicesPressed={setIsGetAllDevicesPressed}
                    filteredResults={filteredResults}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    hasCachedSites={hasCachedSites}
                    pageSize={pageSize}
                    pageFetchedIndex={pageFetchedIndex}
                    setTotalPages={setTotalPages}
                    setTotalDevices={setTotalDevices}
                />

                {/* Excel Button */}
                {
                    filteredResults?.length > 0 && < ExportExcel
                        excelData={exportData}
                        fileName={"Sites Filtered by:" + searchQuery}
                        title={"IPIO Filtered Sites: " + searchQuery}
                        colWidths={
                            [
                                { wch: 20 },
                                { wch: 20 },
                                { wch: 22 },
                                { wch: 13 },
                                { wch: 10 },
                                { wch: 10 },
                                { wch: 20 },
                                { wch: 20 },
                            ]
                        }
                        style={{ height: "50px", fontSize: "16px" }}
                    />
                }

                <div className={classes.ButtonContainer2}>
                    <div style={{ display: "flex", gap: "10px" }}>
                        {/* Filters Button */}
                        <div className={classes.filters}>
                            {categoryFilter && <CategoryFilter setCategoryFilter={setCategoryFilter} categories={["Online", "Offline", "No site name"]} categoryFilter={categoryFilter} />}
                        </div>

                        {/* Sort By Button */}
                        <SortDevices
                            setSortBy={setSortBy}
                            sortBy={sortBy}
                            categories={["Site name - Asc", "Site name - Desc", "Firmware - Asc", "Firmware - Desc", "User Count - Asc", "User Count - Desc"]}
                        />

                        <GetAllPagedDevices
                            searchQuery={searchQuery}
                            setIsLoading={setIsLoading}
                            handleFilterLogic={handleFilterLogic}
                            categoryFilter={categoryFilter}
                            sortBy={sortBy}
                            setSearchQuery={setSearchQuery}
                            setIsGetAllDevicesPressed={setIsGetAllDevicesPressed}
                            isGetAllDevicesPressed={isGetAllDevicesPressed}
                            filteredResults={filteredResults}
                            setTotalPages={setTotalPages}
                            setFilteredResults={setFilteredResults}
                            setMaxPagedFetches={setMaxPagedFetches}
                            maxPagedFetches={maxPagedFetches}
                            pageFetchedIndex={pageFetchedIndex}
                            setPageFetchedIndex={setPageFetchedIndex}
                            setTotalDevices={setTotalDevices}
                            totalDevices={totalDevices}
                            getAllDevices={getAllDevices}
                        />
                    </div>

                    {!groupMode && <Button1 style={{ height: "50px", width: "60px", fontSize: "16px" }} onClick={changeViewType}>
                        <Whisper placement="top" trigger="hover" speaker={tooltip}>
                            <img src={viewType === 1 ? gridIcon : listIcon} style={{ width: "30px", height: "30px" }} />
                        </Whisper>
                    </Button1> }
                </div>




            </div>

            <div className={classes.buttonContainer}>
                {groupMode && <Button1 style={{ width: "250px", height: "50px", fontSize: "15px" }} onClick={AddSitesToGroup}>Add selected sites to {dataManager.getItem('groupClicked')}</Button1>}
                {groupMode && <Button1 style={{ width: "100px", height: "50px", fontSize: "15px" }} onClick={cancel}>Cancel</Button1>}
            </div>


            <Suspense fallback={
                <div style={{ display: "flex", width: "100%" }}>
                    <DeviceLoadingGrid />
                </div>
            }>
                <Await resolve={filteredResults}>
                    {(loadedFilteredResults) => (
                        <div style={{ display: "flex", width: "100%", height:"100%" }}>
                            {isloading ? (
                                <div style={{ display: "flex", width: "100%" }}>
                                    <DeviceLoadingGrid
                                        CardCount={
                                            searchQuery?.length === 2
                                                ? 10
                                                : searchQuery?.length >= 3 && 1
                                        }
                                    />
                                </div>
                            ) : searchQuery !== "" ||
                                searchQuery !== " " ||
                                isGetAllDevicesPressed ? (
                                loadedFilteredResults && loadedFilteredResults?.length !== 0 ? (
                                    viewType === 0 ? (
                                        // Render DevicesGrid when viewType is 0
                                        <DevicesGrid
                                            data={loadedFilteredResults}
                                            HandleSaveSearchQuery={HandleSaveSearchQuery}
                                            totalPages={totalPages}
                                            setTotalPages={setTotalPages}
                                            pageFetchedIndex={pageFetchedIndex}
                                            setPageFetchedIndex={setPageFetchedIndex}
                                            pageSize={pageSize}
                                            setPageSize={setPageSize}
                                            totalDevices={totalDevices}
                                            setTotalDevices={setTotalDevices}
                                            groupMode={groupMode}
                                            apiData={apiData}
                                        />
                                    ) : (
                                        // Render DevicesList when viewType is 1
                                       <DevicesList 
                                            devices={loadedFilteredResults} 
                                            filtering={""} 
                                            hideControls={false}
                                            HandleSaveSearchQuery={HandleSaveSearchQuery} 
                                            totalPages={totalPages}
                                            setTotalPages={setTotalPages}
                                            pageFetchedIndex={pageFetchedIndex}
                                            setPageFetchedIndex={setPageFetchedIndex}
                                            pageSize={pageSize}
                                            setPageSize={setPageSize}
                                            totalDevices={totalDevices}
                                            setTotalDevices={setTotalDevices}
                                            groupMode={groupMode}
                                            update={update}
                                            setUpdate={setUpdate}
                                       />
                                    )
                                ) : (
                                    "No sites found"
                                )
                            ) : (
                                ""
                            )}
                        </div>
                    )}
                </Await>
            </Suspense>
        </div>
    )
}

export default DeviceManager