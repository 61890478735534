import React from "react";

const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

const UseTimeDateFormat = (passedDate) => {
    const date = new Date(passedDate);  // Create a JavaScript Date object
    const day = date.getDate();  // Extract the day
    const month = date.toLocaleString('en-GB', { month: 'long' });  // Format the month
    const year = date.getFullYear(); // Extract the year
    const hours = date.getHours(); // Extract the hours
    let minutes = date.getMinutes(); // Extract the minutes
    const suffix = getDaySuffix(day);

    if (minutes < 10){
        minutes = "0" + minutes
    }

    // Construct the formatted date string
    const formattedDate = `${day}${suffix} ${month} ${year}`;

    const time = hours + ":" + minutes;

    // Construct the formatted time string with UK time zone
    const formattedTime = date.toLocaleTimeString('en-GB', { timeZone: 'Europe/London' });

    // Return the formatted date and time
    return { date: formattedDate, time: time };
}

export default UseTimeDateFormat;