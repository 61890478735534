import React, { useState, useEffect, Children } from 'react';
import classes from './CompanyIndicatorCard.module.css';

// const colors = [
//   { "eyespyfx": "#6495ed" },
//   { "re:sure": "#4b0082" },
//   { "parfas": "#000000" },
//   { "portal_security": "#00008b" },
//   { "solo": "#b22222" },
//   { "knowio": "#149b08" },
//   { "cps": "#089b79" },
//   { "csc": "#08969b" },
//   { "ees": "#5e089b" },
// ]

const companies = [
  "EyeSpyFX", 
  "RESURE",
  "PARFAS", 
  "PORTAL_SECURITY", 
  "SOLO", 
  "KNOWIO", 
  "CPS", 
  "EES", 
  "CSC_SECURITY"
]


const darkenHexColor = (hex, amount) => {
  let usePound = false;

  if (hex[0] === "#") {
    hex = hex.slice(1);
    usePound = true;
  }

  const num = parseInt(hex, 16);
  let r = (num >> 16) - amount;
  let g = ((num >> 8) & 0x00FF) - amount;
  let b = (num & 0x0000FF) - amount;

  r = r < 0 ? 0 : r;
  g = g < 0 ? 0 : g;
  b = b < 0 ? 0 : b;

  return (usePound ? "#" : "") + (r << 16 | g << 8 | b).toString(16).padStart(6, '0');
};

const CompanyIndicatorCard = ({ companyName }) => {
  let statusClass = companyName.trim();

  if (!companies.includes(statusClass)){
    statusClass = "defaultCompany"
  }

  return (
    <div className={`${classes.StatusIndicatorCard} ${classes[statusClass]}`}>
      <h3> {companyName}</h3>
    </div>
  )
}

export default CompanyIndicatorCard;