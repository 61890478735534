import React from "react"
import classes from "./ShowOTPPopup.module.css"
import Button1 from "../../../../Button/Button1";

const ShowOTPPopup = ({pincode, closeModal}) => {




  return (
    <>
      <div className={classes.OTPCodeContainer}>
        <div className={classes.copyButtonContainer}>
          
        </div>

        <div className={classes.pincodeTextContainer}>
            <h1>{pincode}</h1>
          
        </div> 

        <div className={classes.buttonsContainer}>
            <Button1 onClick={closeModal}>Close</Button1>
          </div>
      </div>
    </>
  );
}

export default ShowOTPPopup