import React, { useState } from 'react';
import classes from './CustomDropDown.module.css';
import image from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Drop Down menu.svg'


const CustomDropDown = React.forwardRef(({ showDropDownOptions, placeholder, options, selectedOption, selectedIcon, handleOptionChange, handleBlur, handleDropDownOnClick, isValid, style, icons }, ref) => {
    return (
        <div className={`${classes.customDropdown} ${handleBlur ? "" : classes.invalid}`} ref={ref} >
            <div className={classes.selectedOption} onClick={handleDropDownOnClick}>
                <h3>{selectedOption || placeholder}</h3>   
            </div>
            {showDropDownOptions && (

                <ul className={classes.dropdownOptions} style={style}>
                    {options.map((option, index) => {
                        const normalizedOption = option.toLowerCase();
                        return (
                            <li key={index} onClick={() => handleOptionChange(option)}>
                                {option}
                            </li>
                        );
                    })}
                </ul>
            )
            }
            <img
                className={classes.dropdownArrowImage}
                alt="arrow"
                src={image}
                id="rotateImage"
                onClick={handleDropDownOnClick}
            />
        </div >
    );
});


export default CustomDropDown;
