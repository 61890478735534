import React, { useState, useEffect } from 'react';
import { UseConvertDateTimeToUTC } from '../../hooks/UseConvertDateTimeToUTC';
import UseYearMonthDateShort from '../../hooks/UseYearMonthDateShort';
import classes from "./TimeDatePciker.module.css";
import { UseConvertUTCToLocalTime } from '../../hooks/UseConvertUTCToLocalTime';

const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

const getCurrentDate = () => {
    const newDate = new Date();
    const startDate = UseYearMonthDateShort(newDate);
    const maxDate = UseYearMonthDateShort(newDate.setUTCFullYear(newDate.getUTCFullYear() + 1));
    return { startDate, minDate: startDate, maxDate };
};

const TimeDatePicker = ({ handler, label, preSelectedDateTime }) => {
    const [time, setTime] = useState(getCurrentTime);
    const [date, setDate] = useState(getCurrentDate);

    // Handle time changes
    const handleTimeChange = (event) => {
        const selectedTime = event.target.value;
        setTime(selectedTime);

        if (selectedTime && date) {
            const utcDateTime = UseConvertDateTimeToUTC(date.startDate, selectedTime);
            // const utcDateTime = convertToUTC(date.startDate, selectedTime);
            handler(utcDateTime);
        }
    };

    // Handle date changes
    const handleDateChange = (event) => {
        const selectedDate = {
            startDate: event.target.value,
            minDate: date.minDate,
            maxDate: date.maxDate,
        };
        setDate(selectedDate);

        if (time && selectedDate.startDate) {
            const utcDateTime = UseConvertDateTimeToUTC(selectedDate.startDate, time);
            handler(utcDateTime);
        }
    };

    useEffect(() => {
        const initialTime = getCurrentTime();
        const initialDate = getCurrentDate();
        
        if (preSelectedDateTime != undefined) {
            const localDateTimeObject = UseConvertUTCToLocalTime(preSelectedDateTime);

            const Time = localDateTimeObject.time;
            const newDate = new Date();
            const minDate = newDate.toISOString().split('T')[0]; // Get current date in 'YYYY-MM-DD' format

            // Add one year to the Date object
            newDate.setFullYear(newDate.getFullYear() + 1);

            // Format the updated Date object back to 'YYYY-MM-DD'
            const year = newDate.getFullYear();
            const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(newDate.getDate()).padStart(2, '0');
            const maxDate = `${year}-${month}-${day}`;

            const dateObject = {
                startDate: localDateTimeObject.date,
                minDate,
                maxDate
            };

            setTime(localDateTimeObject.time);
            setDate(dateObject);
        } else {
            setTime(initialTime);
            setDate(initialDate);
            const utcDateTime = UseConvertDateTimeToUTC(initialDate.startDate, initialTime);

            handler(utcDateTime);
        }

    }, []);

    return (
        <div className={classes["container"]}>
            <label htmlFor="appt">{label || "Select Date and Time on:"}</label>

            <input
                type="time"
                id="timePicker"
                name="holiday-timePicker"
                value={time}
                onChange={handleTimeChange}
                className={classes["time-input"]}
            />

            <label htmlFor="appt">{"on the"}</label>

            <input
                type="date"
                id="start"
                className={classes["time-input"]}
                name="holiday-start"
                value={date && date.startDate ? date.startDate : ""}
                onChange={handleDateChange}
                min={date && date.minDate ? date.minDate : ""}
                max={date && date.maxDate ? date.maxDate : ""}
            />
        </div>
    );
};

export default TimeDatePicker;
