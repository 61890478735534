import React from 'react'

import classes from './InputPinCircle.module.css'
import inputOn from '../../../assets/stateIcons/input_blue.png'
import inputOff from '../../../assets/stateIcons/input_grey.png'
import outputGrey from '../../../assets/stateIcons/pin_disabled.png'

const InputPinCircle = ({ inputs, InputPins }) => {
  const defaultProperties = {
    pinState: 0,
    active: 0,
};

while (InputPins.length < 8) {
    const newObj = { ...defaultProperties };
    // Push the new object to the array
    InputPins.push(newObj);
}

  return (
    InputPins.map((InputPin, index) => {
      let InputPinImage;

      if (InputPin.pinState === 0) {
        InputPinImage = `url(${inputOn})`;
      } else if (InputPin.pinState === 1) {
        InputPinImage = `url(${inputOff})`;
      } else if (InputPin.pinState === 2) {
        InputPinImage = `url(${outputGrey})`; 
      }

      return (
        <li key={index}>
        <div 
            className={classes.input}
            style={{ 
                backgroundImage: InputPin.active ? InputPinImage : `url(${outputGrey})`,
                opacity: InputPin.active ? 1.0 : 0.25,
                backgroundSize: 'cover', // Ensure the image covers the entire div
                backgroundPosition: 'center', // Center the image
                backgroundColor: !InputPin.active ? "lightgrey" : "transparent" // Fallback color
            }}
        />
    </li>
      )
    })
  )}

export default InputPinCircle;