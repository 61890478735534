function useParseArrayDeviceData(data) {
    const InputsPins = [21, 20, 16, 12, 4, 3, 2, 1];
    const OutPutPins = [5, 6, 13, 19];
    const Pins = [21, 20, 16, 12, 4, 3, 2, 1, 5, 6, 13, 19];


    const parsedDataArray = data.map((device) => {
        const { id, name, mac, key, ip, host, uuid, online, created, lastLoggedIn, status, tag, networkType, deviceDetails, firmware, type, numInput, numOutput, subGroup, deviceExtraDetails, latestFirmware, userCount, deviceName } = device;

        // Passing Single Device Details to redux store to make these details availabe to other components. e.g. header
        const DeviceDetailsParsed = JSON.parse(deviceDetails);
        const isPinNotificationsActive = Pins.some(pin => {
            const pins = DeviceDetailsParsed[pin];
            return pins !== undefined && pins.notificationActive;
        });

        const singleDeviceSettingData = [isPinNotificationsActive, online, DeviceDetailsParsed.allPinsNotificationActive, DeviceDetailsParsed.allPinsNotificationSound, DeviceDetailsParsed.camectIntegrationType, DeviceDetailsParsed.name, DeviceDetailsParsed.offlineNotificationActive, DeviceDetailsParsed.offlineNotificationSound, DeviceDetailsParsed.onlineNotificationActive, DeviceDetailsParsed.onlineNotificationSound, DeviceDetailsParsed.polarity, DeviceDetailsParsed.polarityReason];

        // Parsing the Pins out into OutPut Pins/ Input Pins - also added the pin number as a property to the OutPut Object.
        const DeviceOutputPins = OutPutPins.map((output) => { const value = DeviceDetailsParsed[output]; return { ...value, Pin: output }; }).filter((obj) => obj !== undefined);
        const DeviceInputPins = InputsPins.map((input) => DeviceDetailsParsed[input]).filter((value) => value !== undefined);

        //Input Device Icons
        const InputDeviceIcons = InputsPins.map((input) => JSON.parse(deviceExtraDetails)[input]).filter((value) => value !== undefined);
        const arrayOfInputDeviceIcons = Object.values(InputDeviceIcons);
        const arrayOfInputDeviceIconsValues = arrayOfInputDeviceIcons.map(obj => obj.icon);

        //Output Device Icons
        const OutputDeviceIcons = OutPutPins.map((output) => JSON.parse(deviceExtraDetails)[output]).filter((value) => value !== undefined);
        const arrayOfOutputDeviceIcons = Object.values(OutputDeviceIcons);
        const arrayOfOutputDeviceIconsValues = arrayOfOutputDeviceIcons.map(obj => obj.icon);

        const InputPins = DeviceInputPins.map((item, index) => {
            let icon = arrayOfInputDeviceIconsValues[index];

            if (icon && icon.includes('.png')) {
                // //slice off .png 
                icon = icon.slice(0, -4) /*+ '.svg';*/
            } else {
                icon = icon /*+ '.svg';*/
            }

            return { ...item, icon };
        });

        const OutputPins = DeviceOutputPins.map((item, index) => {
            let icon = arrayOfOutputDeviceIconsValues[index];
            let DeviceUUID = uuid;
            if (icon && icon.includes('.png')) {

                icon = icon.slice(0, -4)
            } else {
                icon = icon
            }
            return { ...item, icon, DeviceUUID };
        });

        const ResponseData =
            { OutputPins, InputPins, singleDeviceSettingData, id, name, mac, key, ip, host, uuid, online, created, lastLoggedIn, status, tag, networkType, deviceDetails, firmware, type, subGroup, numInput, numOutput, latestFirmware, userCount, deviceName }

        return ResponseData;
    })
    return parsedDataArray;
};

export default useParseArrayDeviceData;
