import React, { Fragment } from 'react'

import SearchIcon from '../../../../../assets/portalAssets/searchbarIconW.png'

import classes from './SideNavV2-Item.module.css'

import { Link, NavLink } from 'react-router-dom';

function SideNavItem(props) {
    return (
        <NavLink
            to={props.route}
            onClick={props.onClick}
            className={({ isActive }) => {
                const linkClasses = [classes['SideNav-item'], classes['itemName']];
                if (isActive) linkClasses.push(classes.active);
                return linkClasses.join(" "); // returns "registerButton" or "registerButton active"
            }}
            end={props.end || null}
           

        >
            <img className={classes.img} src={props.image} alt={props.alt} />
            <h3>{props.text}</h3>

        </NavLink>
    )
}

export default React.memo(SideNavItem);