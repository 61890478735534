import React from 'react'
import classes from './Button1.module.css';

const Button1 = ({ onClick, children, style, propsclassName, disabled, type, onMouseEnter, onMouseLeave, img, imgStyles}) => {
  
  return (
    <button className={`${classes["tableButton-container"]} ${propsclassName}`} style={style} disabled={disabled} type={type} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
      {img && <img className={classes.image} src={img} style={imgStyles}/>}
    </button >
  )
}

export default Button1;
