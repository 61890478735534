import React from 'react'

import classes from './Battery.module.css';
const Battery = ({ BatteryPowerLevel, isActive }) => {

    let BatteryStatus;

    if (BatteryPowerLevel <= 0) {
        BatteryStatus = "alert";
    } else if (BatteryPowerLevel <= 1) {
        BatteryStatus = "alert";
    } else if (BatteryPowerLevel <= 10) {
        BatteryStatus = "alert";
    } else if (BatteryPowerLevel <= 20) {
        BatteryStatus = "warn2";
    } else if (BatteryPowerLevel <= 30) {
        BatteryStatus = "warn";
    } else {
        BatteryStatus = "";
    }

    return (
        /*  the of the battery isActive (is selected) then use the .active class instead */
        // <div className={isActive ? classes.active : classes.battery}>
        <div className={classes.active}>
            <div className={`${classes["battery-level"]} ${classes[BatteryStatus] || ""} `} style={{ width: BatteryPowerLevel + "%" || "100%" }}></div>
        </div>
    );
};
export default Battery