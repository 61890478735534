import React, { useState, useReducer, useRef, useEffect } from "react";

import FormButton from "../../Button/FormButton/FormButton";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from './Login.module.css';

import { LoginHandler } from "../../../../store/auth-actions";
import FormCard from "./FormCard/FormCard";
import Input1 from "../../Inputs/Input1";


const emailReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.includes('@') };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.includes('@') };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const passwordReducer = (state, action) => {
    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.trim().length >= 4 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.trim().length >= 4 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.trim().length >= 4 };
    }
    return { value: '', isValid: false };
};


const Login = (props) => {
    const [formIsValid, setFormIsValid] = useState(false);


    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        value: '',
        isValid: null
    });



    const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
        value: '',
        isValid: null
    });


    const emailInputRef = useRef()
    const passwordInputRef = useRef();


    const { isValid: emailIsValid } = emailState;
    const { isValid: passwordIsValid } = passwordState;

    useEffect(() => {
        const identifier = setTimeout(() => {
            // console.log('Checking form validity!');
            setFormIsValid(emailIsValid && passwordIsValid)

        }, 500);

        return () => {
            // console.log('CLEANUP');
            clearTimeout(identifier);
        };
    }, [emailIsValid, passwordIsValid,]);


    const emailChangeHandler = (event) => {
        dispatchEmail({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    const passwordChangeHandler = (event) => {
        dispatchPassword({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    const validateEmailHanlder = () => {
        dispatchEmail({ type: 'INPUT_BLUR' });
    }

    const validatePasswordHanlder = () => {
        dispatchPassword({ type: 'INPUT_BLUR' });
    }

    const onClearHandler = () => {
        dispatchEmail({ type: 'INPUT_CLEAR', val: "" })
        dispatchPassword({ type: 'INPUT_CLEAR', val: "" });
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submitHandler = (event) => {
        event.preventDefault();

        if (formIsValid) {

            const Email = emailState.value;
            const Password = passwordState.value;


            dispatch(LoginHandler(Email, Password)).then((response) => {
                if (response && response.data && response.data.success) {
                    navigate("Verify");
                }
            })
            return;
        } else if (!emailIsValid) {
            emailInputRef.current.focus();
        }
        else {
            passwordInputRef.current.focus();
        }
    };

    return (
        <FormCard>
            <h2 className={classes.Title}>{props.title || "Log In"}</h2>

            <p className={classes.subInfo}>{props.subInfo || "Please enter your details to access IPIO Portal"}</p>

            <form onSubmit={submitHandler} className={classes["container"]} >
                <ul className={classes["inputs-list"]}>
                    <li>
                        <Input1
                            placeholder="Email"
                            ref={emailInputRef}
                            id="email"
                            type={"email"}
                            isValid={emailState.isValid}
                            value={emailState.value}
                            onChange={emailChangeHandler}
                            onBlur={validateEmailHanlder}
                        />
                    </li>

                    <li>
                        <Input1
                            placeholder="Password"
                            ref={passwordInputRef}
                            id="password"
                            type={"password"}
                            isValid={passwordState.isValid}
                            value={passwordState.value}
                            onChange={passwordChangeHandler}
                            onBlur={validatePasswordHanlder}
                        />
                    </li>
                </ul>


                <FormButton value={props.buttonText || "Log In"} type="submit" />
                <h4 onClick={props.HandleShowModal} className={classes.serviceLink} >{props.serviceLink || "Forgotten Password?"}</h4>
            </form>

            <div>
                <ul className={classes["extraLinks-list"]}>
                    {/* {(props.extraLinks).map((extraLink, index) => (
                        <li className={classes.listItem} key={index}>
                            <h4 className={classes.extraLink}>{extraLink.LinkText || ""}</h4> <Link to={extraLink.to}className={classes.link}>{extraLink.LinkTextBold}</Link>
                        </li>
                    ))} */}

                    {props.extraLinks.map((link, index) => (
                        link.to.startsWith("http") ? (
                            <a
                                key={index}
                                href={link.to}
                                target={link.target || "_blank"}
                                rel="noopener noreferrer"
                            >
                                {link.LinkText} <strong>{link.LinkTextBold}</strong>
                            </a>
                        ) : (
                            <Link key={index} to={link.to} target={link.target || "_self"}>
                                {link.LinkText} <strong>{link.LinkTextBold}</strong>
                            </Link>
                        )
                    ))}
                </ul>
            </div>
        </FormCard>
    );
}

export default Login;

