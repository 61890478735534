import { useState } from 'react'

import { useSelector } from 'react-redux';
import { defer } from 'react-router-dom';
import { getCompanyUserList, } from '../../Api Methods/Api';

import PortalUsersTableLayout from '../../components/Table/PortalUsersTableLayout';

import SearchBar from '../SearchView/Search/SearchBar';

import classes from "./UserManagementPage.module.css"

const UserManagementPageTableView = () => {
  const client = useSelector(state => state.auth.client);
  const companyName = useSelector(state => state.auth.companyName);
  const token = useSelector(state => state.auth.token);
  const userID = useSelector(state => state.auth.userID);

  const apiData = {
    token,
    client
  }

  const [filtering, setFiltering] = useState('');

  const HandleTableFiltering = (e) => {
    setFiltering(e.target.key)
  }

  return (
    <div className={classes['userManagementPage_container']}>
      <SearchBar
        text="text"
        placeholder={"Search Users..."}
        value={filtering}
        onChange={HandleTableFiltering}
      />
      <PortalUsersTableLayout filtering={filtering} HandleTableFiltering={HandleTableFiltering} apiData={apiData} companyName={companyName} />
    </div>
  )
}

export default UserManagementPageTableView

export async function loader() {
  const token = sessionStorage.getItem('token');
  const client = sessionStorage.getItem('client');
  const companyName = sessionStorage.getItem('companyName');

  const apiData = {
    token,
    client
  }

  return defer({
    portalUsers: getCompanyUserList(apiData, companyName),
  })
}
