
import classes from "./linegraph.module.css";

import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Line } from "react-chartjs-2";

import monthData from "./monthData.json";
import monthLables from "./monthLables.json";
import { useEffect, useState } from "react";

defaults.maintainAspectRatio = false;
defaults.responsive = true;

const LinegraphPeaked = ({ events, handleDateClicked, setIsLoading, dateRange, dateClicked }) => {

  var m_names = new Array("Jan", "Feb", "Mar",
    "Apr", "May", "Jun", "Jul", "Aug", "Sep",
    "Oct", "Nov", "Dec");

  const eventsCopy = Array.from(events);
  const eventsRev = eventsCopy.reverse();
  const startDate = new Date(dateRange[0]).toISOString().split('T')[0];
  const endDate = new Date(dateRange[1]).toISOString().split('T')[0];

  //grouped by day
  const groupedEvents = eventsRev.reduce((acc, event) => {
    const eventDate = new Date(event.eventDate).toISOString().split('T')[0];

    if (!acc[eventDate]) {
        acc[eventDate] = [];
    }

    acc[eventDate].push(event);
    return acc;
  }, {});

  function generateDateRange(startDate, endDate) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    const finalDate = new Date(endDate);
    while (currentDate <= finalDate) {
      dateArray.push(new Date(currentDate).toISOString().split('T')[0]); // Format to YYYY-MM-DD
      dateArray.push([""]);
      currentDate.setDate(currentDate.getDate() +1);
    }
    return dateArray;
  }

  const fullDateRange = generateDateRange(startDate, endDate);

  fullDateRange.forEach(date => {
    if (!groupedEvents[date]) {
      groupedEvents[date] = [];
    }
  });
  
  function formatDate(date) {
    var sup = "";

    if (isNaN(date.getTime())){
        return ""
    } else {
      if (date.getDate() == 1 || date.getDate() == 21 || date.getDate() == 31)
      {
          sup = "st";
      }
      else if (date.getDate() == 2 || date.getDate() == 22)
      {
          sup = "nd";
      }
      else if (date.getDate() == 3 || date.getDate() == 23)
      {
          sup = "rd";
      }
      else
      {
          sup = "th";
      }

      var curr_month = date.getMonth();
      return (date.getDate() + sup + " " + m_names[curr_month]);
    }
  }

  const handlePointClick = (event, elements) => {
    if (elements.length > 0) {
      const elementIndex = elements[0].index;
      const dateClicked = fullDateRange[elementIndex];
      handleDateClicked(dateClicked);
    }
  };

  return (
    <div className={classes["lineGraph-container"]}>
      <Line
        data={{
          labels: fullDateRange.map(date => formatDate(new Date(date))) || 0,
          datasets: [
            {
              data: fullDateRange.map(date => groupedEvents[date].length),
              borderColor: "rgb(59, 60, 63)",
              borderWidth: 3.5,
              pointBackgroundColor: fullDateRange.map(date => groupedEvents[date].length > 1 ? 'rgba(22, 138, 173)' : 'rgba(0, 0, 0, 0)' ),
              pointBorderColor: fullDateRange.map(date => groupedEvents[date].length > 1 ? 'rgba(22, 138, 173)' : 'rgba(0, 0, 0, 0)' ),
              pointHoverRadius: 10, 
              pointRadius:3.5,
            },
          ]
        }}
        options={{
          onClick: (event, elements) => {
            if (elements.length > 0 && elements[0].element["$context"].raw !== 0 && elements[0].index !== undefined){
              handlePointClick(event, elements)
            }
          },

          responsive: true,
          elements: {
            line: {
              tension: 0.1,
            }
          },
          scales: {
            y: {
              stepSize: 1,
              display: true,
              border: {
                color: 'rgb(196, 196, 196)',  
                width: 1.5,  
              }, 
              grid: {
                display: false, // Hides y-axis gridlines
              },
            },
            x: {
              border: {
                color: 'rgb(196, 196, 196)',  
                width: 1.5,  
              },
              grid: {
                display: false, // Hides x-axis gridlines
              },
            }
          },
          plugins: {
            // Hides the title and legend from showing
            legend: {
              display: false,
              title: {
                display: false,
              }
            },
      tooltip: {
        enabled: true,  // Enable tooltips (default is true)
        callbacks: {
          // Custom content for the tooltip
          label: (tooltipItem) => {
            const label = fullDateRange[tooltipItem.dataIndex];
            const count = groupedEvents[label].length;
            return `${count} events`;
          },
          title: (tooltipItems) => {
            return `${tooltipItems[0].label}`;
          }
        },
        backgroundColor: "rgba(0,0,0,0.7)", // Customize background color
        titleFont: { size: 16 }, // Customize title font
        bodyFont: { size: 14 }, // Customize body font
        bodyColor: "#fff", // Text color
        borderColor: "rgba(255, 255, 255, 0.8)",
        borderWidth: 1,
        padding: 10,
        displayColors: false, // Disable color box next to the label
      }
          }
        }
       }
      />
    </div>
  );
}

export default LinegraphPeaked;
