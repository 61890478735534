import React, { useState, useReducer, useRef, useEffect } from "react";

import FormButton from "../../Button/FormButton/FormButton";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from "./Register2.module.css"
import classes2 from './Login.module.css';
import IPIOImage from '../../../../assets/ItunesArtwork@2x.png'

import { LoginHandler } from "../../../../store/auth-actions";
import FormCard from "./FormCard/FormCard";
import Input1 from "../../Inputs/Input1";
import { createAppAccount, createPortalPassword } from "../../../../Api Methods/Api";
import Card from "../Card";
import Input from "../../../Input/Input";

const emailReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.includes('@') };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.includes('@') };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.includes('@') };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const appPinReducer = (state, action) => {
    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.trim().length >= 4 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.trim().length >= 4 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.includes('@') };
    }
    return { value: '', isValid: false };
};

const passwordReducer = (state, action) => {
    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.trim().length >= 4 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.trim().length >= 4 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.includes('@') };
    }
    return { value: '', isValid: false };
};

const reEnterpasswordReducer = (state, action) => {
    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.trim().length >= 4 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.trim().length >= 4 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.includes('@') };
    }
    return { value: '', isValid: false };
};

const FullRegistration = (props) => {
    const [formIsValid, setFormIsValid] = useState(false);

    const [nameState, dispatchName] = useReducer(emailReducer, {
        value: '',
        isValid: null
    });

    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        value: '',
        isValid: null
    });

    const [appPinState, dispatchAppPin] = useReducer(appPinReducer, {
        value: '',
        isValid: null
    });

    const [confirmAppPinState, dispatchConfirmAppPin] = useReducer(appPinReducer, {
        value: '',
        isValid: null
    });

    const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
        value: '',
        isValid: null
    });

    const [reEnterPasswordState, dispatchReEnterPassword] = useReducer(reEnterpasswordReducer, {
        value: '',
        isValid: null
    });

    const emailInputRef = useRef();
    const nameInputRef = useRef();
    const appPinInputRef = useRef();
    const confirmAppPinInputRef = useRef();
    const passwordInputRef = useRef();
    const reEnterpasswordInputRef = useRef();

    const { isValid: emailIsValid } = emailState;
    const { isValid: appPinIsValid } = appPinState;
    const { isValid: passwordIsValid } = passwordState;
    const { isValid: reEnterPasswordIsValid } = reEnterPasswordState;

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid((emailIsValid && appPinIsValid && passwordIsValid && reEnterPasswordIsValid) && (passwordState.value === reEnterPasswordState.value))
        }, 500);

        return () => {
            clearTimeout(identifier);
        };
    }, [emailIsValid, appPinIsValid, passwordIsValid, reEnterPasswordIsValid, passwordState, reEnterPasswordState]);

    const nameChangeHandler = (event) => {
        dispatchName({ type: 'USER_INPUT', val: event.target.value });
    }

    const emailChangeHandler = (event) => {
        dispatchEmail({ type: 'USER_INPUT', val: event.target.value });
    }

    const appPinChangeHandler = (event) => {
        dispatchAppPin({ type: 'USER_INPUT', val: event.target.value });
    }

    const confirmAppPinChangeHandler = (event) => {
        dispatchConfirmAppPin({ type: 'USER_INPUT', val: event.target.value });
    }

    const passwordChangeHandler = (event) => {
        dispatchPassword({ type: 'USER_INPUT', val: event.target.value });
    }

    const reEnterpasswordChangeHandler = (event) => {
        dispatchReEnterPassword({ type: 'USER_INPUT', val: event.target.value });
    }

    const validateNameHanlder = () => {
        dispatchName({ type: 'INPUT_BLUR' });
    }

    const validateEmailHanlder = () => {
        dispatchEmail({ type: 'INPUT_BLUR' });
    }

    const validateAppPindHanlder = () => {
        dispatchAppPin({ type: 'INPUT_BLUR' });
    }

    const validatePasswordHanlder = () => {
        dispatchPassword({ type: 'INPUT_BLUR' });
    }

    const validatereEnterPasswordHandler = (event) => {
        dispatchReEnterPassword({ type: 'INPUT_BLUR' });
    }

    const onClearHandler = () => {
        dispatchEmail({ type: 'INPUT_CLEAR', val: "" });
        dispatchAppPin({ type: 'INPUT_CLEAR', val: "" });
        dispatchPassword({ type: 'INPUT_CLEAR', val: "" });
        dispatchReEnterPassword({ type: 'INPUT_CLEAR', val: "" });
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const submitHandler = (event) => {
        event.preventDefault();

        if (formIsValid) {
            const fullName = nameState.value;
            const Email = emailState.value;
            const AppPin = appPinState.value;
            const confirmAppPin = confirmAppPinState.value;
            const Password = passwordState.value;
            const ReEnterPassword = reEnterPasswordState.value;

            createPortalPassword(Email, AppPin, Password).then((response) => {
                    if (response && response.data && response.data.success) {
                        onClearHandler();
                        navigate("/");
                    }
                })

            return;
        } else if (!emailIsValid) {
            emailInputRef.current.focus();
        }
        else if (!passwordIsValid) {
            passwordInputRef.current.focus();
        } else {
            reEnterpasswordInputRef.current.focus();
        }
    };

    return (
        <div className={`${classes.card} ${props.className}`}>
            <h2 className={classes2.Title}>{props.title || "Create account"}</h2>
            <p className={classes2.subInfo}>{props.subInfo || "Please enter your details to create your account"}</p>
            <form onSubmit={submitHandler} className={classes2["container"]} >
                <ul className={classes2["inputs-list"]}>

                    <li>
                        <Input1
                            placeholder="Full Name"
                            ref={nameInputRef}
                            id="fullName"
                            type={"fullName"}
                            isValid={passwordState.isValid}
                            value={nameState.value}
                            onChange={nameChangeHandler}
                            onBlur={validateEmailHanlder}
                        />
                    </li>

                    <li>
                        <Input1
                            placeholder="Email Address"
                            ref={emailInputRef}
                            id="email"
                            type={"email"}
                            isValid={emailState.isValid}
                            value={emailState.value}
                            onChange={emailChangeHandler}
                            onBlur={validateEmailHanlder}
                        />
                    </li>

                    <li>
                        <Input1
                            placeholder="Create an app pin"
                            ref={appPinInputRef}
                            id="appPin"
                            type={"text"}
                            isValid={appPinState.isValid}
                            value={appPinState.value}
                            onChange={appPinChangeHandler}
                            onBlur={validateAppPindHanlder}
                        />
                    </li>

                    <li>
                        <Input1
                            placeholder="Confirm app pin"
                            ref={confirmAppPinInputRef}
                            id="confirmAppPin"
                            type={"text"}
                            isValid={appPinState.isValid}
                            value={confirmAppPinState.value}
                            onChange={confirmAppPinChangeHandler}
                            onBlur={validateAppPindHanlder}
                        />
                    </li>

                    <li>
                        <Input1
                            placeholder="Create a portal password"
                            ref={passwordInputRef}
                            id="password"
                            type={"password"}
                            isValid={passwordState.isValid}
                            value={passwordState.value}
                            onChange={passwordChangeHandler}
                            onBlur={validatePasswordHanlder}
                        />
                    </li>

                    <li>
                        <Input1
                            placeholder="Confirm portal password"
                            ref={reEnterpasswordInputRef}
                            id="reEnterpassword"
                            type={"password"}
                            isValid={reEnterPasswordState.isValid}
                            value={reEnterPasswordState.value}
                            onChange={reEnterpasswordChangeHandler}
                            onBlur={validatereEnterPasswordHandler}
                        />
                    </li>
                </ul>

                <FormButton value={props.buttonText || "Activate"} type="submit" />
            </form>

            <div>
                <ul className={classes["extraLinks-list"]}>
                    {/* {(props.extraLinks).map((extraLink, index) => (
                        <li className={classes.listItem} key={index}>
                            <h4 className={classes.extraLink}>{extraLink.LinkText || ""}</h4> <Link to="/" className={classes.link}>{extraLink.LinkTextBold}</Link>
                        </li>
                    ))} */}
                </ul>
            </div>
        </div >
    );  
}

export default FullRegistration;

