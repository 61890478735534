import React, { useState, useEffect } from "react";
import classes from "./PinInput.module.css";
import InactiveIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/Pin/default - Off.svg"
import HiddenIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/HiddenPin.svg";

const PinInput = ({ InputPin, polarity }) => {
    const { pinName, pinStatusOff, pinStatusOn, pinState, icon, active } = InputPin;

    const lowerCaseStringIcon = icon.toLowerCase();
    const image = lowerCaseStringIcon + " - Off";
    const otherStateImage = icon + " - On";
    const staticPath = "/assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/";

    const imageFromPath = `${process.env.PUBLIC_URL}${staticPath}${image}.svg`
    let otherStateImageFromPath = `${process.env.PUBLIC_URL}${staticPath}${otherStateImage}.svg`;

    const initialPinState = polarity === 0 ? !pinState : pinState;
    const [PinState, setPinState] = useState();

    // const PinClickedHandler = () => {
    //     console.log("Changing Status");
    //     setPinState((prevPinState) => !prevPinState)
    // }

    useEffect(() => {
        setPinState(initialPinState);
    }, [pinState]);

    return (
        <div className={classes["layout"]} style={{ opacity: !active && "50%" }} >
            {/* {!active && <div className={classes["inactiveMessage-container"]}><h2>Pin Hidden</h2></div>} */}
            {!active &&
                <div className={classes["overlay"]}>
                    {/* <div className={classes.line} />
                    <div className={classes.line2} />
                    <img className={classes.HiddenIcon} src={HiddenIcon}></img> */}
                </div>}
            <div className={classes["pin-output"]}>
                <img className={classes.img} src={active ? PinState ? imageFromPath : otherStateImageFromPath : InactiveIcon} />

                <div className={classes["text-wrapper"]}>
                    <h3 className={classes.pinName}>{pinName}</h3>
                    <h2 className={`${classes["pinLable"]} ${active ? PinState ? classes["deactivated"] : classes["activated"] : classes["Inactive"]}`}>
                        {active ? PinState ? pinStatusOff : pinStatusOn : "Inactive"}
                    </h2>
                </div>

            </div>
            <div className={active ? PinState ? classes["pin-deactivated"] : classes["pin-activated"] : classes["pin-Inactive"]} />
        </div>
    )
}

export default PinInput;