import React from "react";

const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};


const UseParseDateMonthYearTime = (passedDate) => {
    console.log(passedDate)
    const date = new Date(passedDate);  // Create a JavaScript Date object
    const day = date.getDate();  // Extract the day in local time
    const month = date.toLocaleString('en-US', { month: 'short' });  // Format the month to abbreviated format
    const year = date.getFullYear(); // Extract the year in local time
    const hours = String(date.getHours()).padStart(2, '0'); // Extract local hours and pad with zero if needed
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Extract local minutes and pad with zero if needed
    const suffix = getDaySuffix(day);

    return `${day}${suffix} ${month}, ${year} ${hours}:${minutes}`;
};



export default UseParseDateMonthYearTime;