import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Button/ButtonSideNav"
import Card from "../../Cards/Card";

import classes from './NotificationCard.module.css';

import userIcon_Black from "../../../../assets/portalAssets/usericonB.png";

import warningNotifcationIcon from "../../../../assets/portalAssets/warningNotificationIconW.png";
import loginIcon from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Navigation Icons/__Logout.svg"
import closeIconW from "../../../../assets/portalAssets/closeIconW.png";
import { uiActions } from "../../../../store/ui-slice";

const NotificationCard = (props) => {
  const lineRef = useRef(null);


  const dispatch = useDispatch();
  let toggleNotification = useSelector(state => state.ui.toggleNotification);

  if (!toggleNotification) {
    toggleNotification = true;
  }

  let headerclasses = '';
  let lineclasses = '';

  if (props.status === 'error') {
    headerclasses = classes.headerError;
    lineclasses = classes.lineError;
  }
  if (props.status === 'success') {
    headerclasses = classes.headerSuccess;
    lineclasses = classes.lineSuccess;
  }

  const cssheaderClasses = `${classes["notification-header"]} ${headerclasses} `;
  const csslineClasses = `${classes.line} ${lineclasses} `;


  const timeDuration = 10;
  // Dismiss 'Check your email...' notification after 10 seconds.
  useEffect(() => {


    // Schedule the removal after 10 seconds
    const timeoutId = setTimeout(() => {
      dispatch(uiActions.toggleNotification());
    }, timeDuration * 1000); // 10000 milliseconds = 10 seconds

    const animationDuration = timeDuration; // seconds, same as the timeout duration
    const lineElement = lineRef.current;
    if (lineElement) {
      lineElement.style.animationDuration = `${animationDuration}s`;
    }

    // Clean up the timeout when the component unmounts or re-renders
    return () => {
      clearTimeout(timeoutId);

    };
  }, [dispatch, toggleNotification]);

  return (

    <div className={`${classes["notification-container"]} ${headerclasses}`} >
      <h4 style={{
      color: props.status === "pending" ? "grey" :
        props.status === "error" ? "var(--Validation-color)" :
          props.status === "successful" ? "black" :
            "lightblue"
    }}>
        {props.title}
      </h4>
      <div className={classes["notification-Content"]}>
      <img className={classes.notificationIcon} src={loginIcon} style={{ background: "20px" }} onClick={() => dispatch(uiActions.toggleNotification())} />
      <h4 style={{
      color: props.status === "pending" ? "grey" :
        props.status === "error" ? "var(--Validation-color)" :
          props.status === "successful" ? "black" :
            "lightblue"
    }}>
        {props.message}
      </h4>
      <img className={classes.closeIcon} src={closeIconW} onClick={() => dispatch(uiActions.toggleNotification())} />
      </div>
    </div>

  );
}

export default NotificationCard;