import { clear } from "@testing-library/user-event/dist/clear";
import { useEffect, useRef, useState } from "react";
import { PowerSearchAPI } from "../../Api Methods/Api";
import { useDispatch } from "react-redux";
import classes from '../../components/PowerSearch/PowerSearch.module.css'
import SearchBar from "../PowerSearch/SearchBar";

const CopyUsersPowersearch = ({apiData, update, setUpdate, suggestions, setSuggestions, queryDeviceList, setQueryDeviceList, redraw, setRedraw}) => {

    const dispatch = useDispatch();
    const formRef = useRef();
    const [query, setQuery] = useState("");

    useEffect(() => {
        console.log(queryDeviceList)
        if (queryDeviceList != "" && queryDeviceList !== " " && queryDeviceList !== null){
            setQuery(queryDeviceList)
            setQueryDeviceList(queryDeviceList)
            setSuggestions([])
        }
    }, [queryDeviceList])


    useEffect(() => {
        console.log(query);
        //setQueryDeviceList(query);
        const powerSearch = async (query) => {
            const reponseData = await PowerSearchAPI(apiData, query, 50, 1, 0, 0, 0, 0, dispatch)
            const suggestedSites = reponseData[0].devices;
            console.log(suggestedSites)
            const filteredSites = suggestedSites.filter(site => site.tag === "" && site.userCount === 0);
            console.log(filteredSites);
            const suggestedSiteIDs = filteredSites.map(siteID => siteID.key);
            setSuggestions(suggestedSiteIDs);
            console.log(suggestedSiteIDs);
        }

        const delayDebounceFn = setTimeout(() => {
            if (query !== "" && query !== " " && query !== null && (query !== queryDeviceList)){
                powerSearch(query)
                setQueryDeviceList(query);
            } else {
                setSuggestions([]);
                setRedraw(redraw+1)
            }
        }, 1700);
        return () => clearTimeout(delayDebounceFn);
    }, [query])

    return (
        <form className={classes.form} ref={formRef}>
            <SearchBar
                text="text"
                placeholder={"Start typing the new Site ID..."}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
            />
            <div className={classes.filters}>
                {/* {categories && <CategoryFilter setCategoryFilter={setCategoryFilter} categories={categories} />} */}
            </div>
        </form>
    )
}

export default CopyUsersPowersearch;