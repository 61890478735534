import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { defer } from 'react-router-dom';
import SearchBar from '../../components/PowerSearch/SearchBar';
import SearchbarSmall from '../../components/UI/Inputs/SearchbarSmall';
import classes from "./DeviceActivityPage.module.css"
import { getInactiveDevices } from '../../Api Methods/Api';
import DeviceActivityTableLayout from '../../components/Table/DeviceActivityTableLayout';

const DeviceActivityPage = () => {

  const client = useSelector(state => state.auth.client);
  const companyName = useSelector(state => state.auth.companyName);
  const token = useSelector(state => state.auth.token);

  const [filtering, setFiltering] = useState('');
  const apiData = {
    token,
    client
  }

  const HandleTableFiltering = (e) => {
    setFiltering(e.target.value)
  }

  return (
    <div className={classes.deviceActivityPageContainer}>
      <DeviceActivityTableLayout filtering={filtering} HandleTableFiltering={HandleTableFiltering} apiData={apiData} companyName={companyName} />
    </div>
  )
}

export default DeviceActivityPage

export async function loader() {
  const token = sessionStorage.getItem('token');
  const client = sessionStorage.getItem('client');
  const companyName = sessionStorage.getItem('companyName');

  const apiData = {
    token,
    client
  }

  return defer({
    deviceActivity: getInactiveDevices(apiData, companyName)
  })
}
