import { io } from 'socket.io-client';

const URL = 'https://ipio.m2mservices.mobi';

export const socket = io(URL, {
    autoConnect: true,
    reconnection: true,          // Enable automatic reconnection (default)
    reconnectionAttempts: 7,     // Max number of reconnection attempts (optional)
    reconnectionDelay: 1000,     // Time between attempts (in ms)
    reconnectionDelayMax: 5000,
});
