import PinInput from "../Input/PinInput";
import ComponentCard from "../../../../Cards/ComponentCard/ComponentCard"
import classes from "./PinInputList.module.css";

const PinInputList = ({InputPins, polarity}) => {
  
  return (
    <ComponentCard>
      <div className={classes["pin-outputs_list"]}>
        <ul>
          {InputPins.map((inputPin, index) =>
            <li key={index}>
              <PinInput key={index} InputPin={inputPin} polarity={polarity} />
            </li>
          )}
        </ul>
      </div>
    </ComponentCard>
  )
}

export default PinInputList;

