import React, { useMemo, useEffect, Suspense, useState } from 'react'

import { DateTime } from 'luxon';

import classes2 from "./UsersTableLayout.module.css";
import classes from "./TableLayout.module.css";
import Card from "./Card";
import Table from './Table/Table';
import TableButton from './Table/TableButton/TableButton';
import fiiller_rows from './Table/MOCK_DATA_3.json'
import { Await, useLoaderData, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loading-icons';
import BasicTable from './Table/Table';
import { getActivityLog, getInactiveDevices } from '../../Api Methods/Api';
import UseTimeDateFormat from '../hooks/UseTimeDateFormat';
import { useDispatch } from 'react-redux';
import Pill_Widget from '../UI/Widgets/Pill_Widget';
import { serviceActions } from '../../store/service-Slice';
import useSetCookie from '../hooks/UseSetCookie';

const DeviceActivityTableLayout = ({ HanldeshowAddUserForm, filtering, HandleTableFiltering, apiData, companyName }) => {

  // ---------------------------- loader data ---------------------------------
  const { deviceActivity } = useLoaderData();
  useEffect(() => {
    if (deviceActivity.isError) {
      return deviceActivity.message;
    }
  }, [deviceActivity])


  const [deviceActivityEvents, setDeviceActivityEvents] = useState(deviceActivity)
  //---------------------------------------------------------------------------


   //------------------------------- responsible for updating the Activity Page data every 1 mintue ------------------------------------------------------
   const dispatch = useDispatch();
   useEffect(() => {

    // set initial portalActivityEvents on mount
    setDeviceActivityEvents(deviceActivity);

    const HandleDeviceEvents= async () => {
        const data = await getInactiveDevices(apiData, dispatch)
        setDeviceActivityEvents(data)
    }

    // Set up a timer to fetch data every 1 minute
    const timer1 = setInterval(() => {

      HandleDeviceEvents();
    }, 60000);
    return () => clearInterval(timer1);
}, []);
//---------------------------------------------------------------------------------------------------------------------
  
  const tablecolumns = [
    {
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
            <h3>Site name</h3>
        </div>
    ),
      accessorKey: 'tag',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
              {row.row.original.tag && row.row.original.tag.trim() !== "" && (
                  <Pill_Widget
                      boolean={true}
                      PillColor_IfTrue={"#919191"}
                      PillColor_IfFalse={"#626a67"}
                      text_IfTrue={row.row.original.tag}
                      className={classes.PillHeight}
                  />
              )}                
          </div>
      )
      }
    },
    {
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
            <h3>Site ID</h3>
        </div>
    ),
      accessorKey: 'key',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueTextBold}>
              {row.row.original.key}
          </div>
      )
      }
    },
    {
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
        <h3>Status</h3>
    </div>
    ),
        accessorKey: 'online',
        cell: (row) => {
            if (row.row.original.online === "YES"){
                return (
                    <>
                        <Pill_Widget
                                boolean={true}
                                PillColor_IfTrue={"#22ca80"}
                                PillColor_IfFalse={"#626a67"}
                                text_IfTrue={"Online"}
                                className={classes.PillHeight}
                            />
                    </>
                )
            } else {
                return (
                    <>
                        <Pill_Widget
                                boolean={false}
                                PillColor_IfTrue={"rgb(101,101,101)"}
                                PillColor_IfFalse={"#ff4f70"}
                                text_IfTrue={"Offline"}
                                className={classes.PillHeight}
                            />
                    </>
                )
            }
        }
    },
    {
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
            <h3>Last interaction</h3>
        </div>
      ),
      accessorKey: 'lastEvent',
      cell: (row) => {
          const { date, time } = UseTimeDateFormat(row.row.original.lastEvent)
          
          if (row.row.original.lastEvent == null){
              return (
                  <>
                    <p className={classes.tableValueText}>Before Jan 1st, 2024</p>
                  </>
                )
              } else {
          return (
            <>
              <p className={classes.tableValueText}>{date + ", " + time}</p>
            </>
          )
        }
      }
    },
    {
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
            <h3>User count</h3>
        </div>
    ),
        accessorKey: 'userCount',
        cell: (row) => {
          return (
            <div>
              <p className={classes.tableValueText}>{row.row.original.userCount}</p>
            </div>
          )
        }
    },
    {
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
            <h3>Owner</h3>
        </div>
    ),
      accessorKey: 'name',
      cell: (row) => {
        let companyName = row.row.original.name;
        if (companyName == "RE:SURE") {
            companyName = "RESURE"
        }

        const companyColors = {
            EyeSpyFX: "rgb(100, 149, 237)",
            RESURE: "rgb(75, 0, 130)",
            PARFAS: "rgb(0, 0, 0)",
            PORTAL_SECURITY: "rgb(0, 0, 139)",
            SOLO: "rgb(178, 34, 34)",
            KNOWIO: "rgb(20, 155, 8)",
            CPS: "rgb(8, 155, 121)",
            CSC_SECURITY: "rgb(8, 150, 155)",
            EES: "rgb(94, 8, 155)"
          };
          
          const companyColor = companyColors[companyName] || "#6e6e6e";

        return (
          <div>
            <Pill_Widget boolean={true} PillColor_IfTrue={companyColor} PillColor_IfFalse={"#626a67"} text_IfTrue={row.row.original.name} className={classes.PillHeight}/>
          </div>
        )
      }
    }
  ];

  const navigate = useNavigate();
  const HandleUipiodOnClick = (DeviceUUID) => {

    dispatch(serviceActions.setDeviceUUID(DeviceUUID));

    useSetCookie("DeviceUUID", DeviceUUID, 1);

    navigate("/Dashboard/SiteView/" + DeviceUUID);
  }

  const deviceActivityData = deviceActivityEvents

  return (
    <div className={`${classes.table} ${classes.center} `}>
      <Card card2={classes.card2}>
        <div className={classes["tableCardLayoutUI-wrapper"]}>
          <div className={classes["top-wrapper"]}>
            <p>To sort, click on the column name</p>
            <small>*Before Jan 1st, 2024 - Events that occured before this are no longer accessible</small>
            <br></br>
          </div>
          <Suspense fallback={
            <div className={classes["loadingSpinner-container"]}>
              <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
            </div>
          }>
            <Await resolve={deviceActivityData}>
              {(loadedtableData) => { 
                const extractedData = loadedtableData.data;
                  // loadedtableData.reverse()
                  return (
                    <BasicTable 
                        data={extractedData} 
                        columns={tablecolumns} 
                        filtering={filtering} 
                        rowClick={false} 
                        ShowPagination={true} 
                        PageSize={50} 
                        paginationOptions={[25, 50, 100]} 
                        showFilterButton={false} 
                        HandleTableFiltering={HandleTableFiltering} 
                    />)
                //return (<BasicTable data={loadedtableData} columns={tablecolumns} filtering={filtering} rowClick={false} ShowPagination={true} PageSize={50} paginationOptions={[25, 50, 100]} showFilterButton={true} HandleTableFiltering={HandleTableFiltering} />)
              }}
            </Await>
          </Suspense>
        </div>
      </Card>
    </div>
  )
}

export default DeviceActivityTableLayout;