import { useEffect } from 'react';
import { socket } from './socket';

export const useSocketHook = (isSocketConnected, socketListeners) => {
    useEffect(() => {
        if (isSocketConnected) {
            socketListeners.forEach(({ socketlistener, callback }) => {
                socket.on(socketlistener, (data) => {
                    callback(data)
                });
            });

            return () => {
                socketListeners.forEach(({ socketlistener }) => {
                    socket.off(socketlistener)
                });
                // socket.off('appPinStates');


            };
        }
    }, [socket, socketListeners]);

    // Optionally return any additional data or functions needed by the component using this hook
};
