import React from 'react'
import Login from '../../UI/Cards/FormCard/Login';
import Verification from '../../UI/Cards/FormCard/Verification';
import Register from '../../UI/Cards/FormCard/Register';




import classes from "./VerifyPage.module.css";

const VerifyPage = () => {

    const staticPath = "/assets/Portal Login Image/";
    const image = "Screenshot 2023-10-30 at 12.19.34";

    const imageFromPath = `${process.env.PUBLIC_URL}${staticPath}${image}.png`

    return (
        <div className={classes["page-layout"]}>
            <Verification className={classes["loginForm-Container"]} extraLinks={[{LinkText:"Didn't receive a code?", LinkTextBold:"Resend"}]}/> 
        </div>
    )
}

export default VerifyPage;