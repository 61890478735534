import React, { useEffect, useState } from "react";
import { getIglooLocks } from "../../Api Methods/Api";
import { useDispatch, useSelector } from "react-redux";
import { useScrollTrigger } from "@mui/material";
import { Await } from "react-router-dom";
import PadlocksList from "./padlocksList";

const Padlocks = () => {

    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
        token,
        client
    }

    const [locks, setPadlocks] = useState([])
    const [update, setUpdate] = useState(0)
    const filtering = ""

    //Pull data on mount
    useEffect(() => {
        console.log(apiData)
        const HandleGetLocks = async () => {
            const response = await getIglooLocks(apiData, dispatch);
            setPadlocks(response.data[0])
            console.log(response.data[0])
        }

        HandleGetLocks();
    }, [update])


    return (
        <Await resolve={locks}>
                <div style={{ display: "flex", width: "100%", height:"100%" }}>
                    <PadlocksList 
                        locks={locks}
                        filtering={filtering}
                        update={update}
                        setUpdate={setUpdate}
                    />
                </div>
           
        </Await>
    );
}

export default Padlocks;

