import React from 'react';
import ReactDOM from 'react-dom';
import classes from './CametContentLayout.module.css';

const Modal = ({ show, onClose, title, children, save, onSave }) => {
    if (!show) return null;

    return ReactDOM.createPortal(
        <div className={classes.modalOverlay}>
            <div className={classes.modalContent}>
                <button className={classes.closeButton} onClick={onClose}>X</button>
                {save && (<button className={classes.saveButton} onClick={onSave}>Save</button>)}
                <h2 className={classes.modalTitle}>{title}</h2>
                {children}
            </div>
        </div>,
        document.body
    );
};

export default Modal;