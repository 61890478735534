import React, { useEffect, useState } from 'react'
import Button1 from '../../../Button/Button1';
import Button2 from '../../../Button/Button2';
import ListOption from '../EditAutomations/ListOption';

import classes from "./SelectOutputs.module.css";

const SelectRule = ({ body, setBody, listItems, outputList, nextPage, inputTypeText, CreateRule }) => {

    const [inputRuleSelected, setInputSelected] = useState("")

    const HandeOnClick = () => {
        //convert array to string
        const selectedOutputsAsString = body.outputs.join(',');
        const inputAsString = body.input.toString();
        let Body = body;

        Body = { ...Body, input: inputAsString, outputs: selectedOutputsAsString, inputRule: inputRuleSelected.inputRule }
        CreateRule(Body);
    }

    //updates the list option text when the user clicks on an element
    useEffect(() => {
        HandleText()
    }, [inputRuleSelected])

    const HandleText = () => {
        const inputDetails = listItems.find(item => item.Pin == body.input);

        const outputs = body.outputs.slice(1);
        const outputDetails = listItems.filter(item => outputs.includes(item.Pin));

        const arrayOfOutPutsPinNames = outputDetails.map((item) => {
            return item.pinName
        })

        const StringOfOutputs = arrayOfOutPutsPinNames.join(",");

        const InputTypeText = inputTypeText != null ? inputTypeText : inputDetails.pinStatusOn;

        const constantText = "When [ " + inputDetails.pinName + " ] is " + InputTypeText + " [" + StringOfOutputs + "]"

        // this true conditon will only return true after the user clicks the first inputRule - re called be the useEffect above.
        const text = inputRuleSelected.inputRuleText != null ? constantText + " will " + inputRuleSelected.inputRuleText.toLowerCase() : constantText

        return text;
    }

    const HandleInputRuleOnClick = (inputTypeObject) => {
        setInputSelected(inputTypeObject)
    }

    return (



        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "20px"}}>

            <ListOption optionText={HandleText()} propsClassName={classes.infoBox} />

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "20px", width: "100%"}}>
                <ul style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", gap: "10px" }}>
                    <Button2 style={{ minWidth: "160px", backgroundColor: inputRuleSelected.inputRuleText == "Match the state" && "var(--Primary-Color)", color: inputRuleSelected.inputRuleText == "Match the state" && "white", border: inputRuleSelected.inputRuleText == "Match the state" && "2px solid lightblue" }} onClick={() => HandleInputRuleOnClick({ inputRule: "0", inputRuleText: "Match the state" })}  >Match the state</Button2>
                    <Button2 style={{ minWidth: "160px", backgroundColor: inputRuleSelected.inputRuleText == "Oppose the state" && "var(--Primary-Color)", color: inputRuleSelected.inputRuleText == "Oppose the state" && "white", border: inputRuleSelected.inputRuleText == "Oppose the state" && "2px solid lightblue" }} onClick={() => HandleInputRuleOnClick({ inputRule: "1", inputRuleText: "Oppose the state" })} >Oppose the state</Button2>
                    <Button2 style={{ minWidth: "160px", backgroundColor: inputRuleSelected.inputRuleText == "Filp the state" && "var(--Primary-Color)", color: inputRuleSelected.inputRuleText == "Filp the state" && "white", border: inputRuleSelected.inputRuleText == "Filp the state" && "2px solid lightblue" }} onClick={() => HandleInputRuleOnClick({ inputRule: "2", inputRuleText: "Filp the state" })} >Filp the state</Button2>
                </ul >
                <div className={classes.SaveButton} onClick={HandeOnClick} >{"Save"}</div>
            </div>
        </div>

    )
}

export default SelectRule