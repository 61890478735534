
import UseDateFormat from "./UseDateFormat";

function useGetEventsMonth(result, selectedMonth) {
    
    // revese array order
    const Events = result.data;
    

    // Get the current year
    const currentYear = new Date().getUTCFullYear();

    // Get all Events for the current month and year
    const filteredDates = Events.filter(event => {
        const date = new Date(event.eventDate);
        return date.getMonth() === selectedMonth && date.getFullYear() === currentYear;
    });

    //Formating the eventDate field
    const EventsForMonth = filteredDates.map((event) => {
        const { eventDate, ...rest } = event;
        const formattedDate = UseDateFormat(eventDate)
        return {
            eventDate: formattedDate,
            ...rest
        }
    })

    // Creating a array that holds all the days in the month that had events and the number of the events
    const resultArray = EventsForMonth.reduce((accumulator, currentValue) => {
        const existingDateObject = accumulator.find(
            (item) => item.date === currentValue.eventDate
        );

        if (existingDateObject) {
            existingDateObject.events += 1;
        } else {
            accumulator.push({
                date: currentValue.eventDate,
                events: 1,
            });
        }

        return accumulator;
    }, []);


    const newArray = resultArray.map((item) => ({

        ...item,
        events: item.events > 30 ? 30 : item.events,
    }));
    // return newArray;
    return resultArray;
};


export default useGetEventsMonth;
