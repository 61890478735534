import { senders } from "./type/senders";
import { notificationTypes } from "./type/notificationTypes";
import { ApiEndpoints } from "./type/API-Endpoints";

import { authActions } from './auth-Slice';
import { uiActions } from "./ui-slice";

export const LoginHandler = (email, password) => {
    return async (dispatch) => {
        dispatch(
            uiActions.renderNotification({
                status: 'pending',
                title: "Verifying",
                message: "Sending Verification code",
            })
        );

        try {
            const body = { email, password };
            const responseData = await fetchData(ApiEndpoints.loginAPI, body);
            const token = responseData.data.msg.token;
            const client = responseData.data.userInfo.client;
            const refreshToken = responseData.data.msg.refreshToken;
            const groups = responseData.data.groups;
            const Email = email;
            const getAllDevices = responseData.data.getAllDevices;
            dispatch(
                authActions.Login({
                    token,
                    client,
                    refreshToken,
                    Email,
                    groups, 
                    getAllDevices
                }));

            dispatch(
                uiActions.renderNotification({
                    status: 'successful',
                    title: 'Verify Code Sent!',
                    message: 'Please check your email or app for the verify code.',
                })
            );

            return (responseData);
        } catch (error) {
            console.error('Login Failed', error);
            dispatch(
                uiActions.renderNotification({
                    status: 'error',
                    title: 'Login Failed',
                    message: "Email or Password is incorrect."
                })
            );
        }
    };
};

export const VerifyHandler = (email, authCode) => {
    return async (dispatch) => {
        dispatch(
            uiActions.renderNotification({
                status: 'pending',
                title: "Verifying",
                message: "Waiting for portal",
            })
        );
        try {
            const body = { 'email': email, 'code': authCode };
            const responseData = await fetchData(ApiEndpoints.twoFactorAPI, body);
            const responseStatus = responseData.data.success
            const userName = responseData.data.userName;
            const userID = responseData.data.userID;
            const companyName = responseData.data.companyName;
            const role = responseData.data.role;


            if (!responseData.data.success) {
                dispatch(
                    uiActions.renderNotification({
                        status: 'error',
                        title: 'Verify Failed',
                        message: responseData.data.msg
                    })
                );
                return;
            }
            
            dispatch(
                authActions.twoFactorVerify({
                    userName,
                    userID,
                    companyName,
                    role,
                }));

            dispatch(
                uiActions.renderNotification({
                    status: 'successful',
                    title: 'Logged In',
                    message: 'Welcome back!',
                })
            );
            return (responseData);
        } catch (error) {
            console.error('Login Failed', error);
            dispatch(
                uiActions.renderNotification({
                    status: 'error',
                    title: 'Verify Failed',
                    message: "Error"
                })
            );
        }
    };
};

const fetchData = async (uri, data) => {
    const response = await fetch(
        uri, {
        method: 'Post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        console.error(response);
        throw new Error('Login Failed.');
    }

    const responseData = await response.json();



    return responseData;
};

