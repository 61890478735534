import { redirect } from "react-router-dom";

export function getTokenDuration() {
    const storedExpirationDate = sessionStorage.getItem("expiration");
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();  
    return duration;
}

export function getAuthToken() {
    const token = sessionStorage.getItem("token");
    const client = sessionStorage.getItem("client");

    if (!token) {
        return null;
    }

    // const tokenDuration = getTokenDuration();

    // if (tokenDuration < 0) {
    //     return 'EXPIRED'
    // }

    const authCredentials = {
        token,
        client,
    }

    return authCredentials;
}

export function tokenLoader(){
    return getAuthToken();
}

export function checkAuthLoader() {
    const authCredentials = getAuthToken();

    const { token, client} = authCredentials;

    if ((!token || token == "null") || (!client || client == "null")) {
        return redirect('/');
    }   
    else {
        return null
    }
}