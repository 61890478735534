import React from 'react';
import Button1 from '../../Button/Button1';
import classes from './SelectIcon.module.css';


const SelectIcon = React.forwardRef(({ propsClassName, propsImgClassName, showDropDownOptions, placeholder, options, selectedOption, selectedIcon, handleOptionChange, handleBlur, handleDropDownOnClick, isValid, style, icons }, ref) => {
    return (
        <div className={`${classes.customDropdown} ${handleBlur ? "" : classes.invalid} ${propsClassName}`} ref={ref} >
            <div className={classes.selectedOption} onClick={handleDropDownOnClick} ref={ref}>
                <div className={classes.selectedOptionContent}>
                    <img className={classes.img} src={selectedIcon} alt="Selected Icon" />
                    <h3 className={classes.selectedOptionTitle}>{selectedOption}</h3>
                </div>
            </div>
            {showDropDownOptions && (
                <div className={`${classes.fadeInFromTop} ${classes.dropDownContainer}`}>
                    <h3 className={classes.dropDownTitle}>Change Icon</h3>
                    <h4 className={classes.dropDownSubTitle}>Select a new icon below</h4>

                    <ul className={classes.iconList}>
                        {options.map((option, index) => {
                            const normalizedOption = option.toLowerCase();
                            return (
                                <li
                                    className={classes.iconItem}
                                    key={index}
                                    onClick={() => handleOptionChange(option, icons[normalizedOption])}
                                >
                                    {icons[normalizedOption] && (
                                        <img
                                            className={classes.img}
                                            src={icons[normalizedOption]}
                                            alt={`${option} icon`}
                                        />
                                    )}
                                </li>
                            );
                        })}
                    </ul>

                    <Button1
                        onClick={() => handleOptionChange(selectedOption, selectedIcon)}
                        propsclassName={classes.cancelButton}
                    >
                        Cancel
                    </Button1>
                </div>
            )}
            {/* <img
                className={classes.dropdownArrowImage}
                alt="arrow"
                src={image}
                id="rotateImage"
                onClick={handleDropDownOnClick}
            /> */}
        </div>
    );
});

export default SelectIcon;

