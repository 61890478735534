import React, { Children, useEffect, useState, useRef } from 'react'

import classes from "./CreateAutomation.module.css";


import Modal from '../../../../Modal/Modal';

const CreateAutomation = ({ HandleSelectAutomation, numInput, holdiayMode }) => {
    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        //reset states for scheduled Action
        if (ClearStatesChildComponetRef.current && ClearStatesChildComponetRef.current.HandleResetAllStates) {
            // Call the child method
            ClearStatesChildComponetRef.current.HandleResetAllStates();
        }
    }
    //-------------------------------------------------------------------------------------------------

    const handleSelectAutomation = (AutomationID) => {
        HandleSelectAutomation(AutomationID);
        HandleCloseModal()
    }

    return (
        <>
            <Modal ref={ModalDialog} title={"Create Automation"} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "700px", minHeight: "420.5px" }} modalLayoutStyle={{ alignItems: "center" }}>
                <div className={classes.container}>

                    <h3> What type of Automation?</h3>

                    {/* check if number of inputs is less than 0, if so than dont show */}
                    {numInput != 0 && <button className={classes.listOption} onClick={() => handleSelectAutomation(2)}>
                        <h4>IFTTT Rule</h4>
                    </button>}


                    <button className={classes.listOption} onClick={() => handleSelectAutomation(0)}>
                        <h4>Scheduled Action</h4>
                    </button>
                    <button className={classes.listOption} onClick={() => handleSelectAutomation(1)}>
                        <h4>Reminder</h4>
                    </button>

                    {holdiayMode.length === 0 && <button className={classes.listOption} onClick={() => handleSelectAutomation(3)}>
                        <h4>Holiday Mode</h4>
                    </button>}

                </div >
            </Modal>

            <div className={classes.GroupCardContentLayout} onClick={() => HandleShowModal()}>
                <div className={`${classes.container} ${classes.rotate}`} >
                    <h2 className={classes.rotate}>+</h2>
                </div>

            </div >
        </>
    )
}

export default CreateAutomation;




