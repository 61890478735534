import React, { useEffect, useRef } from 'react'
import Login from '../../UI/Cards/FormCard/Login';
import Verification from '../../UI/Cards/FormCard/Verification';
import Register from '../../UI/Cards/FormCard/Register';

import FormCard from '../../UI/Cards/FormCard/FormCard/FormCard';

// import loginImage from "../../../assets/LoginImage.png";
import loginImage from "../../../assets/Portal Login Image/LoginImage.jpeg";


import classes from "./LoginV2.module.css";
import Modal from '../../Modal/Modal';
import SetTag from '../../Modal/ModalLayouts/SetTag';
import InputForm from '../../forms/InputForm';
import { useDispatch, useSelector } from 'react-redux';

import { resetPortalPassword } from "../../../Api Methods/Api"
import { authActions } from '../../../store/auth-Slice';
import ResetPortalPasswordForm from '../../forms/ResetPortalPasswordForm';
import SimpleSingleInputForm from '../../forms/SimpleSingleInputForm';
import { useNavigate } from "react-router-dom";
import { uiActions } from '../../../store/ui-slice';
const LoginPage = () => {


    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();
    }

    //---------------------------------------------------------------------------------------------

    const token = useSelector(state => state.auth.token);
    const client = useSelector(state => state.auth.client);

    const apiData = {
        token,
        client
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleOnFormSuccess = async (email) => {
        const response = await resetPortalPassword(apiData, email, dispatch)
        if (response.data.success) {
            HandleCloseModal();
            dispatch(
                uiActions.renderNotification({
                    status: 'successful',
                    title: "Password Reset ",
                    message: "We've Send you an email with instructions on how to reset your password. ",
                })
            );
        }
    }

    return (
        <>

            <Modal ref={ModalDialog} title={"Reset Password"} modalName={"Login"} HandleShowModal={HandleShowModal}>
                <SimpleSingleInputForm isInputEmail={true} placeholder={"example@outlook.com..."} label={"Please enter your email to reset your password"} submitButtonText={"Reset Password"} handleOnFormSuccess={handleOnFormSuccess} />
            </Modal>

            <div className={classes["page-layout"]}>

                <div className={classes.container} >
                    <img className={classes.imageContainer} src={loginImage} loading={'eager'} />

                    <Login className={classes.card} formType="emailPassword" HandleShowModal={HandleShowModal} extraLinks={[{ LinkText: "First time here?", LinkTextBold: "Activate account", to: "/Register" }, { LinkText: "Don't like change?", LinkTextBold: "Use classic portal", to: String("https://portal.m2mservices.mobi/classic/")}]} />
                </div>


            </div>
        </>
    )
}

export default LoginPage