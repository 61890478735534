import React from 'react';
import { Link } from 'react-router-dom';

class CustomLink extends React.Component {
  handleClick = (event) => {
    if (event.button === 1) {
      window.open(this.props.to, '_blank');
    }
  }

  render() {
    return (
      <Link {...this.props} onMouseUp={this.handleClick} />
    );
  }
}

export default CustomLink;
