import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    toggleNotification: false,
    notification: { title: "title", message: "message" },
    notificationTray: [],
    showSideNav: false,
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        toggleNotification(state, action) {

            
            if (action.payload === false) { 
                 // hides any current pop up notifications while opening the tray.
                state.toggleNotification = false
            } else {
            state.toggleNotification = !state.toggleNotification;
            }
        },
        renderNotification(state, action) {

            if (state.toggleNotification) {
                state.toggleNotification = !state.toggleNotification;
            }
            const currentTimestamp = new Date().getTime();
            const date = new Date(currentTimestamp);

            // Extract hours and minutes
            const hours = date.getHours();
            const minutes = date.getMinutes();

            // Convert hours to 12-hour format and determine am/pm
            const formattedHours = hours % 12 || 12; // Convert 0 to 12
            const period = hours < 12 ? 'am' : 'pm';

            // Add leading zeros to minutes if needed
            const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

            // Format the time as "hh:mm am/pm"
            const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;


            const notification = {
                status: action.payload.status,
                title: action.payload.title,
                timeStamp: formattedTime,
                message: action.payload.message,
            }

            state.notification = notification

            if (action.payload.status !== "pending") {
                state.notificationTray = [notification, ...state.notificationTray]
            }

            state.toggleNotification = !state.toggleNotification;

        },
        removeNotification(state, action) {
            const notificationIdToRemove = action.payload;

            // If the item exits, remove it from the array
            if (notificationIdToRemove !== -1) {
                //Create a new array without the item to remove

                
                const updatedItems = [
                    ...state.notificationTray.slice(0, notificationIdToRemove),
                    ...state.notificationTray.slice(notificationIdToRemove + 1)
                ];
                // Return the state with the updated items array
                state.notificationTray = updatedItems;
            } 
        },
        clearNotification(state, action) {
            state.notificationTray= [];
        },
        pushNotificationToNotificationTray(state, action) {

            let newNotification;
            if (state.notificationTray && state.notificationTray.length > 5) {
                newNotification = state.notificationTray.slice(0, state.notificationTray.length);
            }

            state.notificationTray.unshift({
                status: action.payload.status,
                title: action.payload.title,
                message: action.payload.message,
            })

        },
        showNotification(state, action) {
            state.notification = {
                status: action.payload.status,
                title: action.payload.title,
                message: action.payload.message,
            };

            let newNotification;
            if (state.notificationTray && state.notificationTray.length > 5) {
                newNotification = state.notificationTray.slice(0, state.notificationTray.length);
            }

            state.notificationTray = [
                {
                    status: action.payload.status,
                    title: action.payload.title,
                    message: action.payload.message,
                },
                ...(newNotification || []) // Use the existing array or initialize as an empty array
            ];

            sessionStorage.setItem('notificationTray', JSON.stringify([
                {
                    status: action.payload.status,
                    title: action.payload.title,
                    message: action.payload.message,
                },
                ...state.notificationTray
            ]));

        },
        updateNotificationTray(state, action) {

            state.notificationTray = action.payload;

            sessionStorage.setItem('notificationTray', JSON.stringify(action.payload));
        },
        GetnotificationTray(state, action) {
            state.notificationTray = (sessionStorage.getItem("notificationTray") != '') ? JSON.parse(sessionStorage.getItem("notificationTray")) : []
        },
        ShowSideNav(state, action) {
            state.showSideNav = action.payload;
        },


    },
})

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;