import React, { useState } from 'react'
import { setTag } from '../../../Api Methods/Api';
import Input from '../../CustomDropDown/Input/Input';
import Button1 from '../../UI/Button/Button1';
import classes2 from "../../forms/InviteUserForm.module.css"
import classes from "./SetTag.module.css"
import DataManager from '../../../hooks/DataManager';

const SetNameForUsers = ({ placeholder, HandleCloseModal, handleSaveName, setNameSet }) => {

    const dataManager = new DataManager();
    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');

    const [name, setName] = useState('');

    

    const handleInputChange = (event) => {
        setName(event.target.value);
        setNameSet(event.target.value)
        dataManager.setItem('setNameCopyUsers', event.target.value)
    };

    const handleCancel = () => {
        setName('');
        HandleCloseModal();
    };

    handleSaveName = () => {
        setNameSet(name)
    }

    return (
        <form onSubmit={handleSaveName} method="dialog" >
            <Input
                type="text"
                value={name}
                onChange={handleInputChange}
                placeholder={placeholder}
            />
        </form>
    )
}

export default SetNameForUsers;