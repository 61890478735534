import React, { useRef } from 'react'

import classes from './PaginationControls.module.css';

import FirstPage_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Page - First.svg";
import PrevPage_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Page - Back.svg";
import NextPage_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Page - Forward.svg";
import LastPage_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Page - Last.svg";
import DropDown_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Drop Down menu.svg";

const PaginationControls = ({paginationOptions, table, PageSize, setPageSize }) => {
    return (
        <div className={classes["paginationButtons-container"]}>

            <button className={classes["pagination_button"]} disabled={!table.getCanPreviousPage()} onClick={() => table.setPageIndex(0)}>
                <img className={classes["button_image"]} src={FirstPage_Icon} onClick={() => table.setPageIndex(0)} />
            </button>

            <button className={classes["pagination_button"]} style={{}} disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()} >
                <img className={classes["button_image"]} src={PrevPage_Icon}></img>
            </button>

            <input className={classes["currentPage-box"]} type="number" value={table.getState().pagination.pageIndex + 1} onChange={(e) => table.setPageIndex(e.target.value - 1)} />
            <div>  <h4> {"of "}{table.getPageCount()}</h4></div>

            <button className={classes["pagination_button"]} disabled={!table.getCanNextPage()} onClick={() => table.nextPage()} >
                <img className={classes["button_image"]} src={NextPage_Icon}></img>
            </button>

            <button className={classes["pagination_button"]} disabled={!table.getCanNextPage()} onClick={() => table.setPageIndex(table.getPageCount() - 1)}   >
                <img className={classes["button_image"]} src={LastPage_Icon} />
            </button>

             <div className={classes["pageSize-container"]} >
                <label htmlFor="pageSize">Show </label>
                <select className={classes["dropdown"]} id="pageSize" value={PageSize} onChange={(e) => setPageSize(e.target.value)}>
                    {paginationOptions.map((pageSize, index) => (
                        <option key={index} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export default PaginationControls