import React from 'react'

import classes from "./RoundedInput.module.css"

const RoundedInput = ({ value, placeholder, onChange }) => {
    return (
        <input className={classes.input} value={value} placeholder={placeholder} onChange={onChange} maxLength="2" type="text" />
    )
}

export default RoundedInput