import React, { useEffect, useState } from "react";
import { getKeyboxes } from "../../Api Methods/Api";
import { useDispatch, useSelector } from "react-redux";
import { useScrollTrigger } from "@mui/material";
import { Await } from "react-router-dom";
import PadlocksList from "../Padlocks/padlocksList";

const Keyboxes = () => {

    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
        token,
        client
    }

    const [keyboxes, setKeyboxes] = useState([])
    const [update, setUpdate] = useState(0)
    const filtering = ""

    useEffect(() => {
        const HandleGetKeyboxes = async () => {
            const response = await getKeyboxes(apiData, dispatch);
            setKeyboxes(response.data[0]);
            console.log(response.data[0]);
        }

        HandleGetKeyboxes();

    }, [update]) 

    return (
        
            <Await resolve={keyboxes}>
                {console.log(keyboxes)}
                <div style={{ display: "flex", width: "100%", height:"100%" }}>
                    <PadlocksList 
                        locks={keyboxes}
                        filtering={filtering}
                        update={update}
                        setUpdate={setUpdate}
                    />
                </div>
        </Await>
        
    )
}

export default Keyboxes;