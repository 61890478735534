import { authActions } from "../../store/auth-Slice";
import { getRefreshToken } from "../Api";

const putRequest = async (uri, data, headers, dispatch) => {
    const response = await fetch(
        uri, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: headers.token,
            Client: headers.client,
        },
    });
    if (!response.ok) {
        throw new Error('Fetching failed.');
    }

    const responseData = await response.json();

    if (responseData && responseData.success) {
        return responseData;
    }
    return responseData.data.success;
};

export default putRequest


