import React, { useState, useReducer, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import CustomDropDown from "../CustomDropDown/CustomDropDown";
import classes from './InviteUserForm.module.css';
import Button1 from "../UI/Button/Button1";
import { changeDeviceOwner, changeDropDown } from "../../Api Methods/Api";
import { uiActions } from "../../store/ui-slice";
import { useDispatch } from "react-redux";
import { Dropdown } from "rsuite";


// Reducer function
const dropDownReducer = (state, action) => {

    if (action.type === 'TOGGLE_DROPDOWN') {
        return { ...state, showDropDownOptions: !state.showDropDownOptions, isValid: null };
    }

    if (action.type === 'SELECT_OPTION') {
        return { ...state, selectedOption: action.payload, showDropDownOptions: false, isValid: action.val != "DropDown" };
    }

    if (action.type === 'BLUR_DROPDOWN') {
        return { ...state, showDropDownOptions: false, isValid: action.val != "DropDown" };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { selectedOption: null, isValid: null };
    }
    //otherwise 
    return { state };
};

const DropDownForm = ({ apiData, DeviceUUID, companyName, NotificationTitle, label, dropDownOptions, setName, HandleCloseModal, update, setUpdate, siteDevices, setSiteDevices }) => {

    const [formIsValid, setFormIsValid] = useState(false);


    // DropDown Drop Down reducer  ----------------
    const [dropDownState, dispatchDropDown] = useReducer(dropDownReducer, {
        showDropDownOptions: false,
        selectedOption: '', // Initial selectedOption state
        isValid: null
    });

    const { showDropDownOptions: showDropDownDropDownOptions, selectedOption: selectedDropDownOption } = dropDownState;

    const dropDownDropdownRef = useRef();

    const { isValid: dropDownIsValid } = dropDownState;

    useEffect(() => {
        const identifier = setTimeout(() => {
            // console.log('Checking form validity!');
            setFormIsValid(dropDownIsValid)
        }, 500);

        return () => {
            // console.log('CLEANUP');
            clearTimeout(identifier);
        };
    }, [dropDownIsValid]);

    // Drop down handlers ---------------

    const handleDropDownDropDownOnClick = () => {
        dispatchDropDown({ type: 'TOGGLE_DROPDOWN' });
    };

    const handleDropDownOptionChange = (option) => {
        dispatchDropDown({ type: 'SELECT_OPTION', payload: option });
    };
    const handleDropDownBlur = () => {
        dispatchDropDown({ type: 'BLUR_DROPDOWN' });
    };

    const onClearHandler = () => {
        dispatchDropDown({ type: 'INPUT_CLEAR', val: "" })
    }

    const dispatch = useDispatch();

    const submitHandler = async (event) => {
        event.preventDefault();

        if (formIsValid) {

            const dropDown = dropDownState.selectedOption;

            const response = await changeDeviceOwner(apiData, DeviceUUID, dropDown, dispatch)
            if (response) {
                //setName(dropDown);
                HandleCloseModal();
                dispatch(
                    uiActions.renderNotification({
                        status: 'successful',
                        title: "Owner Changed",
                        message: NotificationTitle + " - owner was changed to " + dropDown,
                    })
                );

                setSiteDevices((prevSiteDevices) =>
                    prevSiteDevices.map((device) =>
                        device.uuid === DeviceUUID ? { ...device, name: dropDown } : device
                    )
                );
            }
            onClearHandler();

            return;

        } if (!dropDownIsValid) {
            dropDownDropdownRef.current.focus();
        }
    };
    return (
        <form onSubmit={submitHandler} className={classes["container"]} method="dialog" >
            <ul className={classes["inputs-list"]}>
                <div className={classes.label}>{label}</div>
                <li className={classes["input-container"]}>
                    <CustomDropDown
                        placeholder={companyName || "EyeSpyFx"}
                        options={dropDownOptions}
                        selectedOption={selectedDropDownOption}
                        handleOptionChange={handleDropDownOptionChange}
                        handleBlur={handleDropDownBlur}
                        handleDropDownOnClick={handleDropDownDropDownOnClick}
                        showDropDownOptions={showDropDownDropDownOptions}
                        isValid={dropDownIsValid}
                        ref={dropDownDropdownRef}
                    />
                </li>
                <div className={classes["button-container"]}>
                    <Button1 className={classes.submitButton} type="submit"> Change Owner</Button1>
                </div>
            </ul>

        </form>


    )


}

export default DropDownForm;

