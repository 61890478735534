
import React, { useState, useEffect} from 'react'

import UseYearMonthDateShort from '../../hooks/UseYearMonthDateShort';

import classes from "./TimeDatePciker.module.css"


const addOneYear = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Add one year to the Date object
    date.setUTCFullYear(date.getUTCFullYear() + 1);

    // Format the updated Date object back to 'YYYY-MM-DD'
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

const addOneDay = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Add one year to the Date object
    // date.setUTCFullYear(date.getUTCFullYear() + 1);
    date.setUTCDate(date.getUTCDate() + 1);

    // Format the updated Date object back to 'YYYY-MM-DD'
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

const getCurrentDate = (label) => {
    const newDate = new Date();
    
    let startDate = UseYearMonthDateShort(newDate);
    const maxDate = UseYearMonthDateShort(newDate.setUTCFullYear(newDate.getUTCFullYear() + 1));

    return { startDate, minDate: startDate, maxDate };
}

const TimeDatePicker = ({ handler, label, preSelectedTime }) => {
    // Initialize state with current time
    const [time, setTime] = useState();
    const [date, setDate] = useState();

    const convertDateToUTC = (date) => {

        console.log(date.startDate);


        const [year, month, date2] = date.startDate.split('-');

        console.log(year);
        console.log(month);
        console.log(date2);

        // Split the input time into hours and minutes
        // const [year, month, date2] = date.startDate.split('-').map(Number);

        // console.log(date2)

        // // // Create a new Date object for the current date
        // // const now = new Date();

        // // // Set the local time (hours and minutes)
        // // now.setHours(hours);
        // // now.setMinutes(minutes);
        // // now.setSeconds(0); // Optionally set seconds to 0

        // // now.setFullYear(year);
        // // now.setMonth(month);
        // // now.setDate(date2);

        // if (date2 < 01) {

        // }
        
        const dateInt = parseInt(date2-1);

        // // const twoDigitMonth = 
        const utcDate = `${year}-${dateInt}-${date2}`
        console.log(utcDate);

        
        // // Convert the local time to UTC using toISOString()
        // const utcHours = now.getUTCHours().toString().padStart(2, '0'); // Get the hours in UTC
        // const utcMinutes = now.getUTCMinutes().toString().padStart(2, '0'); // Get the minutes in UTC

        // // Return the UTC time in 'HH:MM' format
        // return `${utcHours}:${utcMinutes}`;
    };

    // Handler for time change
    const handleTimeChange = (event) => {
        const Time = event.target.value
        setTime(Time);
        if (Time != undefined || Time != null) {
            handler(Time, date);
        }
    };

    // Handler for time change
    const handleDateChange = (event) => {
        const Date = {
            startDate: event.target.value,
            minDate: date.minDate,
            maxDate: date.maxDate
        }

        setDate((Date))


        if (Date != undefined || Date != null) {

            // const utcDate = convertDateToUTC(Date);
            console.log(Date)
            handler(time, Date)
        }
    };

    const [initialLoad, setInitialLoad] = useState(true);

    
    
    // const localTime =  Time;
    // const utcTime = convertTimeToUTC(Time);
    // console.log(localTime)
    // console.log(parseInt(localTime))
    // console.log(parseInt(utcTime))
    // if (parseInt(localTime) == 0 && parseInt(utcTime) == 23){
    //     console.log("first")
    // }    
    useEffect(() => {
        // if (preSelectedTime != undefined || preSelectedTime != null) {

        //     const Time = UseParseTime(preSelectedTime)

        //     const newDate = new Date();
        //     const minDate = UseYearMonthDateShort(newDate);
        //     const passedDate = UseYearMonthDateShort(preSelectedTime)
        //     const maxDate = addOneYear(passedDate);

        //     const dateObject = {
        //         startDate: passedDate,
        //         minDate,
        //         maxDate
        //     }
        //     setDate(dateObject)
        //     setTime(Time)

        //     console.log(dateObject);
        //     console.log(Time);


            

            
        //     if (initialLoad) {
        //         console.log("here");
        //         const utcTime = convertTimeToUTC(Time);                
        //         handler(utcTime, dateObject)
        //         setInitialLoad(false);
        //     }

        // } else {
            const Time = getCurrentTime();
            const Date = getCurrentDate();
        
            setTime(Time);
            setDate(Date);
            
            if (initialLoad) {          
                handler(Time, Date)
                setInitialLoad(false);
            }
        
    }, []);

    return (
        <div className={classes["container"]}>

            <label htmlFor="appt">{label || "Select Date and Time on:"}</label>

            <input
                type="time"
                id="timePicker"
                name="holiday-timePicker"
                value={time}
                onChange={handleTimeChange}
                className={classes["time-input"]}
            />
            <label htmlFor="appt">{"on the"}</label>

            <input
                type="date"
                id="start"
                className={classes["time-input"]}
                name="holiday-start"
                value={date && date.startDate ? date.startDate : null}
                onChange={handleDateChange}
                min={date && date.minDate ? date.minDate : null}
                max={date && date.maxDate ? date.maxDate : null}
            />
        </div>
    )
}

export default TimeDatePicker