import React, { useState, useMemo, useEffect } from 'react'
import classes from './Table.module.css';

import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, Pagination, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';

import PaginationControls from './PaginationControls';
import { Navigate, useNavigate } from 'react-router-dom';

import useSetCookie from '../../hooks/UseSetCookie';
import { serviceActions } from '../../../store/service-Slice';
import { useDispatch } from 'react-redux';
import Button1 from '../../UI/Button/Button1';

const BasicTable = ({ data, columns, allColumns, ShowPagination, filtering, rowClick, PageSize, paginationOptions, tableClasses, showFilterButton, setExportData, exportFormatFunction }) => {

  // const revesedData = data.reverse(); 
  const hiddenColumns1 = ['Users', 'Initial Activation', 'Firmware', 'IP Address',]; // Names of columns to hide
  const hiddenColumns2 = ['Users', 'Initial Activation', 'Firmware', 'IP Address', 'Tag']; // Names of columns to hide
  let [filteredColumns, setFilteredColumns] = useState(columns)

  const [columnVisibility, setColumnVisibility] = useState({});


  //hide eventDate column when not searching events by month
  // useEffect(() => {
  //   if (filtering == "") {
  //     setColumnVisibility((prev) => ({
  //       ...prev,
  //       eventDate: false, // Assuming you want to toggle visibility of 'col2'
  //     }));
  //   } else {
  //     setColumnVisibility((prev) => ({
  //       ...prev,
  //       eventDate: true, // Assuming you want to toggle visibility of 'col2'
  //     }));
  //   }
  // }, [filtering])


  // reverse data to start at most revent event
  // data.reverse()



  useEffect(() => {
    // Function to handle changes in the first media query
    const handleIsMobile1 = () => {
      if (window.innerWidth > 600 && window.innerWidth <= 1050) {
        // Filter out hidden columns for <= 1050px
        setFilteredColumns(columns.filter(column => !hiddenColumns1.includes(column.header)));
      } else if (window.innerWidth <= 600) {
        // Filter out additional hidden columns for <= 600px
        setFilteredColumns(columns.filter(column => !hiddenColumns2.includes(column.header)))
      } else {
        // Show all columns for > 1050px
        setFilteredColumns(columns);
      }
    };

    // Initial check for if screen has been resized
    handleIsMobile1();

    // Add event listeners for changes in the media queries
    window.addEventListener('resize', handleIsMobile1);


    // Clean up by removing event listeners when component unmounts
    return () => {
      window.removeEventListener('resize', handleIsMobile1);

    };
  }, [columns]);

  // const [showPagination, setShowPagination] = useState(ShowPagination && true);
  const [pageSize, setPageSize] = useState(PageSize || 25);


  const [sorting, setSorting] = useState([])
  const table = useReactTable({
    data,
    columns: filteredColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      // sorting: [{ id: 'tag', desc: true }],
      globalFilter: filtering ? filtering : undefined,
      columnVisibility
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
  });

  useEffect(() => {
    if (filtering != "") {
      // Access filtered rows
      const filteredData = table.getFilteredRowModel().rows.map(row => row.original);
      const tempExportData = filteredData.map(exportFormatFunction)

      setExportData(tempExportData)
    }
  }, [data, filtering])


  {/* Set the page size manually  */ }
  useEffect(() => {
    table.setPageSize(pageSize)

  }, [table, pageSize]);

  const navigate = useNavigate();
  const setcookie = useSetCookie();
  const dispatch = useDispatch();

  const handleRowClick = (row) => {
  };

  const handleColumnVisibilityChange = (columnId, isVisible) => {
    // Get the current visibility settings
    const currentVisibility = JSON.parse(localStorage.getItem('columnVisibility')) || {};
    // Update visibility for the specific column
    const updatedVisibility = { ...currentVisibility, [columnId]: isVisible };
    // Save updated settings to local storage
    localStorage.setItem('columnVisibility', JSON.stringify(updatedVisibility));
  };


  const [showColumnFilters, setShowColumnFilters] = useState(false)
  return (
    <>
      <div className={(tableClasses && tableClasses["w3-container"]) || (classes && classes["w3-container"])}>
        {/* Column Visibility Fitler   */}
        {showFilterButton &&
          <div style={{ display: "flex", paddingLeft: "10px", paddingBottom: "10px", alignItems: "center", gap: "10px" }}>
            <Button1 onClick={() => setShowColumnFilters(prevShowColumnFilters => !prevShowColumnFilters)}>{showColumnFilters ? "Done" : "Show more"} </Button1>
            {showColumnFilters && <div className="inline-block border border-black shadow rounded" style={{ display: "flex", padding: "00px" }}>

              {table.getAllLeafColumns().map(column => {
                return (
                  <div key={column.id} className="px-1">
                    <label>
                    <input
                        type="checkbox"
                        checked={column.getIsVisible()}
                        onChange={(e) => {
                          column.getToggleVisibilityHandler()(e); // Update table state
                          handleColumnVisibilityChange(column.id, e.target.checked); // Persist to localStorage
                        }}
                      />
                      {' '}
                      {column.columnDef.filterLabel || 'Unnamed Column'}
                    </label>
                  </div>
                )
              })}
            </div>}
          </div>}
        <table className={(tableClasses && tableClasses["w3-table-all"]) || (classes && classes["w3-table-all"])}>
          <thead />
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} onClick={header.column.
                  getToggleSortingHandler()}
                >
                  {<div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {
                      { asc: ' 🔼', desc: ' 🔽' }[header.column.getIsSorted() ?? null
                      ]
                    }
                  </div>
                  }
                </th>
              ))}
            </tr>
          ))}
          <thead />
          <tbody>
            {table.getRowModel().rows.map(row =>
              <tr key={row.id} onClick={() => rowClick ? handleRowClick(row) : ""}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            )}
          </tbody>
        </table>
        {ShowPagination && table.getFilteredRowModel().rows.length > 10 && <PaginationControls paginationOptions={paginationOptions} table={table} PageSize={pageSize} setPageSize={setPageSize} />}
      </div>
    </>
  );
}

export default BasicTable;