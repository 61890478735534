import React from 'react'
import { authActions } from '../../store/auth-Slice';
import { getRefreshToken } from '../Api';

const postRequest = async (uri, data, headers, dispatch) => {

    const response = await fetch(
        uri, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: headers.token,
            Client: headers.client,
        },
    });
    if (!response.ok) {
        if (response.status === 401) {
            // Handle 401 error: acquire new token and retry the request
            const newApiData = await getRefreshToken();
            // if token expired and user navigates to new page - loaders cant use dispatch so reload as getRefreshToken as passed new token and refresh tokens to session storage.
            dispatch ? dispatch(authActions.UpdateToken(newApiData)) :
                window.location.reload();

            const newresponseData = await postRequest(uri, data, newApiData, dispatch);
            return newresponseData
        }
        throw new Error("Fetching failed: " + response.statusText);
    }

    const responseData = await response.json();

    if (responseData.message == 'sent') {
        return true;
    }
    return responseData;
};

export default postRequest