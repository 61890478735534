import React, { useState, useReducer, useRef, useEffect, useImperativeHandle, forwardRef } from "react";


import CustomDropDown from "../CustomDropDown/CustomDropDown";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from './InviteUserForm.module.css';

import ToggleInput from "../UI/ToggleInput";
import Input from "../CustomDropDown/Input/Input";
import Button1 from "../UI/Button/Button1";
import { resetPortalPassword } from "../../Api Methods/Api";

const extraLinks = [
    {
        LinkTextBold: "Subscribe to get release inputs",
    },
]


const emailReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.includes('@') };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.includes('@') };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.includes('@') };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const inputReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.length > 0 };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const SimpleSingleInputForm = ({ isInputEmail, placeholder, label, submitButtonText, handleOnFormSuccess }) => {

    const [formIsValid, setFormIsValid] = useState(false);

    const [inputState, dispatchInput] = useReducer(isInputEmail ? emailReducer : inputReducer, {
        value: '',
        isValid: null
    });

    const inputInputRef = useRef();
    const { isValid: inputIsValid } = inputState;

    useEffect(() => {
        const identifier = setTimeout(() => {
            // console.log('Checking form validity!');
            setFormIsValid(inputIsValid)
        }, 500);

        return () => {
            // console.log('CLEANUP');
            clearTimeout(identifier);
        };
    }, [inputIsValid]);

    const inputChangeHandler = (event) => {
        dispatchInput({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    const validateInputHandler = () => {
        dispatchInput({ type: 'INPUT_BLUR' });
    }

    const onClearHandler = () => {
        dispatchInput({ type: 'INPUT_CLEAR', val: "" })
    }

    const submitHandler = (event) => {
        event.preventDefault();
        if (formIsValid) {
            const inputtedText = inputState.value;

            handleOnFormSuccess(inputtedText);
            onClearHandler();
            return;
        } else if (!inputIsValid) {
            inputInputRef.current.focus();
        }
    };

    return (
        <form onSubmit={(event) => submitHandler(event)} className={classes["container"]} method="dialog" >
            <ul className={classes["inputs-list"]}>
                <div className={classes.label}>{label}</div>
                <li className={classes["input-container"]}>

                    <Input
                        className={classes.input}
                        placeholder={placeholder}
                        ref={inputInputRef}
                        id="Input"
                        type={"text"}
                        isValid={inputState.isValid}
                        value={inputState.value}
                        onChange={inputChangeHandler}
                        onBlur={validateInputHandler}
                    />
                </li>
                <div className={classes["button-container"]}>
                    <Button1 className={classes.submitButton} type="button" onClick={onClearHandler}>Clear</Button1>
                    <Button1 className={classes.submitButton} type="submit">{submitButtonText || "Submit"}</Button1>
                </div>
            </ul>
        </form>
    )
}

export default SimpleSingleInputForm;

