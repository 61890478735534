export const UseConvertUTCToLocalTime = (dateTime, dateValue, timeValue) => {
    const date = new Date(dateTime);  // Convert input UTC string to Date object

    // Get the local date part
    const localDate = date.toLocaleDateString('en-CA'); // Format 'YYYY-MM-DD'

    // Get the local time part
    const localTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Set to true if you want 12-hour format
    });

    // Return the object with separate date and time
    return { date: localDate, time: localTime };
}