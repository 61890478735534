import React from 'react';
import ExtraUsersIcon from './UserIcon/ExtraUsersIcon';
import classes from './ExtraUsersList.module.css';

const ExtraUsersList = (props) => {
    const { array, className, userInitials } = props;

    let extraUsers = [];


    if (array.length > 8) {
        // Use slice to limit the array to 6 elements
        const sum = array.length - 6
        const negativeNumber = -sum;
        extraUsers = array.slice(0, negativeNumber);
        extraUsers = extraUsers.slice(0, -2);
    }else{
        // used to remove two from the array to account for the first two users already being rendered with initial.
        extraUsers = array.slice(0, -2);
    }

    return (
        <ul className={classes.ulist}>
            {Array.isArray(extraUsers) &&
                extraUsers.map((item, index) => {
                    const opacity = 1 - (index + 1) / extraUsers.length * 0.6; // Adjust the factor as needed
                    return (
                        <li className={classes.list} key={index} style={{ opacity }}>
                            <ExtraUsersIcon item className={className} userInitials={userInitials} />
                        </li>
                    );
                })}
        </ul>
    );
};

export default ExtraUsersList;




/*
import React from 'react';
import classes from './ExtraUsersList.module.css';

const ExtraUsersList = (props) => {
    const { array, renderComponent} = props;
    return (
        <ul className={classes.ulist} style={ulStyle}>
            {Array.isArray(array) &&
                array.map((item, index) => (
                    <li className={classes.list} key={index} style={{ opacity: 1 - index * 0.4 }}>
                        {renderComponent(item)}
                    </li>
                ))}
        </ul>
    );
};

export default ExtraUsersList;

*/
