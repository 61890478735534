import React from 'react';
import ReactDOM from 'react-dom';
import classes from '../../Table/LogsTableLayout.module.css';

const DatePickerModal = ({ show, onClose, title, children, onSelect }) => {
    if (!show) return null;

    return ReactDOM.createPortal(
        <div className={classes.modalOverlay}>
            <div className={classes.modalContent}>
                <button className={classes.closeButton} onClick={onClose}>X</button>
                <button className={classes.saveButton} onClick={onSelect}>Select</button>
                <h2 className={classes.modalTitle}>{title}</h2>
                {children}
            </div>
        </div>,
        document.body
    );
};

export default DatePickerModal;