import React, { Children, useEffect, useState, useRef } from 'react'

import classes from "./IFTTTRuleLayout.module.css";
import arrowIcon from "../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";
import Pill_Widget from '../../../Widgets/Pill_Widget';
import Modal from '../../../../Modal/Modal';
import List from './List';
import AffectedSwitchesList from './AffectedSwitchesList';
import Button1 from '../../../Button/Button1';
import { deleteScheduleApi, setInputRuleApi, updateScheduleApi } from '../../../../../Api Methods/Api';
import { useDispatch, useSelector } from 'react-redux';
import ScheduledAction from './ScheduledAction';
import SelectOutputs from './SelectOutputs';

const ScheduleContentLayout = ({ item, OutPutOptions, fetchAutomations }) => {

    const { id: scheduleID, action, pin: pins, time, days, active, reminder: isReminder } = item;
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);

    const title = isReminder ? "Reminder" : "Scheduled Action"
    const showRemoveScheduleButton = true;
    const apiData = {
        token,
        client
    }

    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        //reset states for scheduled Action
        if (ClearStatesChildComponetRef.current && ClearStatesChildComponetRef.current.HandleResetAllStates) {
            // Call the child method
            ClearStatesChildComponetRef.current.HandleResetAllStates();
        }

        //reset model screens
        HandelResetModal();
    }

    const [modalTitle, setModalTitle] = useState("Edit " + title)
    const [modalComponent, setModalComponent] = useState()
    //--------------------------------------------------------------------------------------------
    const [modalPageIndex, setModalPageIndex] = useState(null);



    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandelResetModal();
                setModalPageIndex(null);
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);


        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    //constants for updating content on each modal page
    const [isFormValid, setIsFormValid] = useState(false);

    const [ListArray, setListArray] = useState(OutPutOptions)

    const HandelResetModal = () => {
        setModalPageIndex(null);
    }


    const [body, setBody] = useState({
        "id": scheduleID,
        "deviceUUID": DeviceUUID,
        "pin": pins, //   "pin": [ "5", "13", "19" ]
        "days": days, // [ "0", "1" ]
        "time": time, // "23:59"
        "active": active, //: 1,
        "action": action, // "Arm"
        "reminder": isReminder  //0 = schedule, 1 = Reminder
    })

    // re-render modal to show new modal componet. 
    useEffect(() => {
        switch (modalPageIndex) {
            case 1:
                setModalComponent(<ScheduledAction body={body} OutPutOptions={OutPutOptions} title={title} isReminder={isReminder} nextPage={setModalPageIndex} onSubmit={UpdateSchedule} DeleteSchedule={DeleteSchedule} showRemoveScheduleButton={showRemoveScheduleButton} />)
                HandleShowModal();
                break;
            case 3:
                setModalComponent(<SelectOutputs body={body} setBody={setBody} listItems={OutPutOptions} modalPageIndex={modalPageIndex} nextPage={setModalPageIndex} showAutomation={isReminder} time={time} />)
                break;
        }

    }, [modalPageIndex])


    const dispatch = useDispatch();

    const DeleteSchedule = async () => {
        const response = await deleteScheduleApi(apiData, scheduleID, dispatch);
        if (response && response.success) {
            HandleCloseModal();
            fetchAutomations();
        }

    }

    const UpdateSchedule = async (body) => {
        const response = await updateScheduleApi(apiData, body, dispatch);
        if (response && response.success) {
            HandleCloseModal();
            fetchAutomations();
        }
    }

    console.log(OutPutOptions)
    const PinsPinNames = OutPutOptions.filter(output => (pins.includes(String(output.Pin)) || pins.includes((output.Pin))));
    const reversePins = PinsPinNames.reverse()
    const pinNames = reversePins.map(item => item.pinName).join(', ');

    const sortedDays = () => {
        const Days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        const selectedDayNames = days.map(dayNumber => Days[dayNumber]).join(',');
      
        if (days.length === 7) {
          return "everyday";
        } else if (selectedDayNames === "Mon,Tue,Wed,Thu,Fri") {
          return "on weekdays";
        } else if (selectedDayNames === "Sat,Sun") {
          return "on weekends";
        } else {
          return "on " + selectedDayNames.split(',').join(', ');
        }
      };

    return (
        <>
            <Modal ref={ModalDialog} title={modalTitle} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "700px", minHeight: "420.5px" }} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>
            <div className={classes.GroupCardContentLayout} >

                <div className={classes["top-wrapper"]}>
                    <div className={classes["siteDetails-layout"]}>
                        <h3 style={{ margin: "0px" }}>{title}</h3>

                        <div className={classes["indicatorsCards-container"]}>
                            <Pill_Widget height={{ height: "25px" }} boolean={active} PillColor_IfTrue={"var(--Active-color)"} PillColor_IfFalse={"var(--Inactive-color)"} text_IfTrue={"Active"} text_IfFalse={"Inactive"} className={classes.PillHeight} />
                        </div>
                    </div>

                </div>

                <div className={classes["middle-wrapper"]}>
                    {isReminder ? <h3 style={{ fontWeight: "400", margin: "0px" }} className={classes.tagName}>{`You will be reminded to ${action} ${pinNames} at ${time} ${sortedDays}`}</h3> :
                        <h3 style={{ fontWeight: "400", margin: "0px" }} className={classes.tagName}>{`${pinNames} will ${action} at ${time} ${sortedDays()}`}</h3>
                    }
                </div>

                <div className={classes["bottom-wrapper"]}>
                    <div className={classes.arrowButton} onClick={() => setModalPageIndex(1)}>
                        <img className={classes.Icon} src={arrowIcon} />
                    </div>
                </div>
            </div >
        </>
    )
}

export default ScheduleContentLayout;




