import React, { useEffect, useState } from 'react'
import Button1 from '../../../Button/Button1';
import Button2 from '../../../Button/Button2';
import ListOption from '../EditAutomations/ListOption';

import classes from "./SelectOutputs.module.css";

const SelectType = ({ body, setBody, setinputTypeText, listItems, outputList, nextPage }) => {

    const HandeOnClick = (inputType, inputTypeText) => {
        setBody(prevBody => ({ ...prevBody, inputType: inputType }));
        setinputTypeText(inputTypeText)
        nextPage(2);
    }

    const [pinStatusObject, setPinStatusObject] = useState({});


    const HandleText = () => {
        const inputDetails = listItems.find(item => item.Pin == body.input);
        const text = "When [ " + inputDetails.pinName + " ] is " + inputDetails.pinStatusOn + " - ";
        return text;
    }


    useEffect(() => {
        const inputDetails = listItems.find(item => item.Pin == body.input);
        setPinStatusObject({
            pinStatusOn: inputDetails.pinStatusOn,
            pinStatusOff: inputDetails.pinStatusOff
        })
    }, [body.input, listItems]);


    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", gap: "20px" }}>
            {/* <div className={classes.nextButton} onClick={HandeOnClick}>{">"}</div> */}

            <ListOption optionText={HandleText()} propsClassName={classes.infoBox} />
            <ul style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", gap: "10px" }}>
                <Button2 style={{ minWidth: "160px" }} onClick={() => HandeOnClick("0", pinStatusObject.pinStatusOn)}> {"is " + pinStatusObject.pinStatusOn}</Button2>
                <Button2 style={{ minWidth: "160px" }} onClick={() => HandeOnClick("1", pinStatusObject.pinStatusOn + " or " + pinStatusObject.pinStatusOff)}>{"is " + pinStatusObject.pinStatusOn + " or " + pinStatusObject.pinStatusOff}</Button2>
                <Button2 style={{ minWidth: "160px" }} onClick={() => HandeOnClick("2", pinStatusObject.pinStatusOff)}>{"is " + pinStatusObject.pinStatusOff}</Button2>
            </ul>
        </div>
    );
}

export default SelectType