import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../../Modal/Modal';


import SelectInput from '../CreateRule/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import SelectOutputs from './SelectOutputs';
import ScheduledAction from './ScheduledAction';
import List from './List';
import ScheduleActionList from './ScheduleActionList';
import { createScheduleApi } from '../../../../../Api Methods/Api';


const CreateSchedule = ({ OutPutOptions, isReminder, setShowAutomation, fetchAutomations }) => {
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);

    const title = isReminder ? "Reminder" : "Scheduled Action"
    const showRemoveScheduleButton = false;
    const apiData = {
        token,
        client
    }

    const [modalComponent, setModalComponent] = useState(null);
    const [modalPageIndex, setModalPageIndex] = useState(0);

    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        //reset states for scheduled Action
        if (ClearStatesChildComponetRef.current && ClearStatesChildComponetRef.current.HandleResetAllStates) {
            // Call the child method
            ClearStatesChildComponetRef.current.HandleResetAllStates();
        }

        HandelResetModal();
    }
    //-------------------------------------------------------------------------------------------------

    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandelResetModal();
                setModalPageIndex(null);
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);


        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    const HandelResetModal = () => {
        setModalPageIndex(null);
        setShowAutomation(null);
    }


    const [body, setBody] = useState({
        "id": "", //4203
        "deviceUUID": DeviceUUID,
        "pin": "", //   "pin": [ "5", "13", "19" ]
        "days": "", // [ "0", "1" ]
        "time": "", // "23:59"
        "active": "", //: 1,
        "action": "", // "Arm"
        "reminder": ""  //0 = schedule, 1 = Reminder
    })

    useEffect(() => {
        switch (modalPageIndex) {
            case 0:
                setModalComponent(<SelectOutputs body={body} setBody={setBody} listItems={OutPutOptions} modalPageIndex={modalPageIndex} nextPage={setModalPageIndex} isReminder={isReminder} />)
                HandleShowModal();
                break;
            case 1:
                setModalComponent(<ScheduledAction body={body} OutPutOptions={OutPutOptions} title={title} isReminder={isReminder} nextPage={setModalPageIndex} onSubmit={CreateSchedule} showRemoveScheduleButton={showRemoveScheduleButton}/>)
                break;
            case 3:
                setModalComponent(<SelectOutputs body={body} setBody={setBody} listItems={OutPutOptions} modalPageIndex={modalPageIndex} nextPage={setModalPageIndex} isReminder={isReminder} />)
                break;
        }
    }, [modalPageIndex]); // Empty dependency array ensures it runs only once after initial render

    const dispatch = useDispatch();

    const CreateSchedule = async (body) => {

        const response = await createScheduleApi(apiData, body, dispatch);
        if (response && response.success) {
            HandleCloseModal();
            fetchAutomations();
        }

    }

    return (
        <>
            <Modal ref={ModalDialog} title={"Create Automation"} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "700px", minHeight: "420.5px" }} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>
        </>
    )
}

export default CreateSchedule