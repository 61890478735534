import { useDispatch, useSelector } from "react-redux";
import { getSensors } from "../../Api Methods/Api";
import { useEffect, useState } from "react";
import { Await } from "react-router-dom";
import PadlocksList from "../Padlocks/padlocksList";

const Sensors = () => {

    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
        token,
        client
    }

    const [sensors, setSensors] = useState([])
    const [update, setUpdate] = useState(0)
    const filtering = ""

    //Pull data on mount
    useEffect(() => {
        console.log(apiData)
        const HandleGetSensors = async () => {
            const response = await getSensors(apiData, dispatch);
            setSensors(response.data[0])
            console.log(response.data[0])
        }

        HandleGetSensors();
    }, [update])


    return (
        <Await resolve={sensors}>
                <div style={{ display: "flex", width: "100%", height:"100%" }}>
                    <PadlocksList 
                        locks={sensors}
                        filtering={filtering}
                        update={update}
                        setUpdate={setUpdate}
                        isSensors={true}
                    />
                </div>
           
        </Await>
    );
}


export default Sensors;