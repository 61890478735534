import { useState, useEffect } from 'react';

function useTimeDifferenceInSeconds(formattedTime) {
  const [timeDifferenceInSeconds, setTimeDifferenceInSeconds] = useState(0);

  useEffect(() => {
    if (formattedTime) {
      const formattedTimeParts = formattedTime.split(':');
      const formattedHours = parseInt(formattedTimeParts[0]);
      const formattedMinutes = parseInt(formattedTimeParts[1]);
      const formattedSeconds = parseInt(formattedTimeParts[2]);

      const currentDate = new Date();
      const currentHours = currentDate.getHours();
      const currentMinutes = currentDate.getMinutes();
      const currentSeconds = currentDate.getSeconds();

      // Calculate the difference in seconds
      let timeDiffSeconds = ((currentHours * 3600 + currentMinutes * 60 + currentSeconds) - (formattedHours * 3600 + formattedMinutes * 60 + formattedSeconds));

      // Ensure time difference is positive
      if (timeDiffSeconds < 0) {
        timeDiffSeconds += 24 * 3600; // Add 24 hours to get the difference in the next day
      }

      setTimeDifferenceInSeconds(timeDiffSeconds);
    }
  }, [formattedTime]);

  return timeDifferenceInSeconds;
}

export default useTimeDifferenceInSeconds;
