import React, { useState, useEffect } from 'react'

import classes from './TimerIndicator.module.css';

const TimerIndicator = (props) => {
  const [toggleState, setToggleState] = useState(1);
  useEffect(() => {
    setToggleState(props.status);
  }, [props.status]);

  let title = "";
  let statusClass = "";


  if (toggleState === "loading") {
    title = "Getting Stats";
    statusClass = classes.styleLoading;
  }
  else if (toggleState === 1) {
    title = "Just Now";
    statusClass = classes.style1;
  } else if (toggleState === 2) {
    title = "30s ago";
    statusClass = classes.style2;
  } else if (toggleState === 3) {
    title = "45s ago";
    statusClass = classes.style3;
  }

  return (
    <div className={` ${classes.container} ${statusClass} ${props.className} `}>
      <h2>{title || "..."}</h2>
    </div>
  )
}

export default TimerIndicator