import ComponentCard from "../../../../Cards/ComponentCard/ComponentCard";
import EditPinOutput from "../OutPut/EditPinOutput";
import classes from "./PinOutputList.module.css";

const EditPinOutputList = ({ OutputPins, polarity, HandleShowEditPinForm, setPinDetails }) => {
    return (
        <ComponentCard id="OutPutPinListCard" className={classes["pin-switches"]}>
            {OutputPins.map((outPutPin, index) =>
                <EditPinOutput key={index} OutPutPin={outPutPin} polarity={polarity} HandleShowEditPinForm={HandleShowEditPinForm} setPinDetails={setPinDetails} />
            )}
        </ComponentCard>
    )
}

export default EditPinOutputList;