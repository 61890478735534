import React from 'react'

import classes from "./SearchbarSmall.module.css";

const SearchbarSmall = React.forwardRef((props, ref) => {
    return (
        <div className={classes.container}>
            <input
                className={classes.input}
                placeholder={props.placeholder}
                ref={ref}
                id={props.id}
                type={props.type}
                isValid={props.isValid}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                style={props.style}
                spellCheck={false}
            />

          
        </div >
    );
});

export default SearchbarSmall