import { authActions } from "../../store/auth-Slice";
import { getRefreshToken } from "../Api";
import getRequest from "./getRequest";

const deleteRequest = async (uri, headers, dispatch) => {
    try {
        const response = await fetch(uri, {
            method: 'DELETE',
            headers: {
                Authorization: headers.token,
                Client: headers.client,
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Handle 401 error: acquire new token and retry the request
                const newApiData = await getRefreshToken();

                // if token expired and user navigates to new page - loaders cant use dispatch so reload as getRefreshToken as passed new token and refresh tokens to session storage.
                dispatch ? dispatch(authActions.UpdateToken(newApiData)) :
                 window.location.reload();

                const newresponseData = await getRequest(uri, newApiData, dispatch);
                return newresponseData
            }
            throw new Error("Delete failed: " + response.statusText);
        }

        const responseData = await response.json();

        // if response contains data
        if (responseData.data) {

            // clear logs api returns only response.success so check if data not not exit first. 
            if (responseData.data === undefined || responseData.data === null) {
                if (responseData.success) {
                    return responseData.success;
                }
            }

            // most other api return data.success
            if (responseData.data.success === false) {
                throw new Error("delete failed");
            }
        }

        return responseData;
    } catch (error) {
        console.error("Error in deleteRequest:", error);
        throw error; // Re-throw the error to propagate it to the caller    
    }
};


export default deleteRequest;
