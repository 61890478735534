import React, { useEffect } from 'react'

const useClickOutside = (NotificationCounterRef, buttonRef, wrapperRef, onClickOutside) => {

    useEffect(() => {
        /**
         * Invoke Function onClick outside of element
         */
        function handleClickOutside(event) {
            // Ensure wrapperRef.current and buttonRef.current are defined and contain method exists
            const wrapperContains = wrapperRef?.current && typeof wrapperRef.current.contains === 'function';
            const buttonContains = buttonRef?.current && typeof buttonRef.current.contains === 'function';

            // Check if the event target is outside the wrapper and button
            if (wrapperContains && buttonContains) {
                if (!wrapperRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                    // Check if NotificationCounterRef.current exists before accessing its properties/methods
                    if (NotificationCounterRef?.current && typeof NotificationCounterRef.current.contains === 'function') {
                        if (!NotificationCounterRef.current.contains(event.target)) {
                            onClickOutside();
                        }
                    } else {
                        onClickOutside();
                    }
                }
            } else {
                // If either wrapperRef.current or buttonRef.current is not defined or doesn't have a contains method, skip the check for both
                onClickOutside();
            }
        }


        // Bind click event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [buttonRef, wrapperRef, onClickOutside]);
}

export default useClickOutside;
