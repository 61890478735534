import React, { useState, useReducer, useRef, useEffect } from "react";

import classes from './Verification.module.css';
import classes2 from "./Login.module.css";

import FormButton from "../../Button/FormButton/FormButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { VerifyHandler } from "../../../../store/auth-actions";
import Input1 from "../../Inputs/Input1";
import { Link } from "react-router-dom";
import { resendOTP } from "../../../../Api Methods/Api";

const extraLinks = [
    {
        LinkTextBold: "Activite Account",
    },
    {
        LinkTextBold: "User Legacy Portal",
    },
]


const verifyCodeReducer = (state, action) => {
    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: true };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: true };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.includes('@') };
    }
    //otherwise 
    return { value: '', isValid: false };
};


const FormCard = (props) => {
    const [formIsValid, setFormIsValid] = useState(false);


    const [verifyCodeState, dispatchVerifyCode] = useReducer(verifyCodeReducer, {
        value: '',
        isValid: null
    });

    const verifyCodeInputRef = useRef()

    const { isValid: verifyCodeIsValid } = verifyCodeState;

    useEffect(() => {
        const identifier = setTimeout(() => {
            // console.log('Checking form validity!');
            setFormIsValid(verifyCodeIsValid)
        }, 500);

        return () => {
            // console.log('CLEANUP');
            clearTimeout(identifier);
        };
    }, [verifyCodeIsValid]);


    const verifyCodeChangeHandler = (event) => {
        dispatchVerifyCode({ type: 'USER_INPUT', val: event.target.value });
    }

    const validateVerifyCodeHandler = (event) => {
        dispatchVerifyCode({ type: 'INPUT_BLUR' });
    }

    const onClearHandler = () => {
        dispatchVerifyCode({ type: 'INPUT_BLUR', val: "" });
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const email = useSelector(state => state.auth.email);

    const submitHandler = (event) => {
        event.preventDefault();

        if (formIsValid) {


            const verifyCode = verifyCodeState.value;

            dispatch(VerifyHandler(email, verifyCode)).then((response) => {
                if (response && response.data && response.data.success) {
                    navigate('/Dashboard')
                }
            })


            // onClearHandler();

            return;
        } else if (!verifyCodeIsValid) {
            verifyCodeInputRef.current.focus();
        }
    };

    return (
        <div className={`${classes.card} ${props.className}`}>
            <h2 className={classes2.Title}>{"Verification"}</h2>
            <p className={classes2.subInfo}>{props.subInfo || "We've sent you a code via app notification & email"}</p>

            <form onSubmit={submitHandler} className={classes2["container"]} >
                <ul className={classes2["inputs-list"]}>
                    <li>
                        <Input1
                            className={classes2.input}
                            placeholder="Authentication Code"
                            ref={verifyCodeInputRef}
                            id="verifyCode"
                            type={"text"}
                            isValid={verifyCodeState.isValid}
                            value={verifyCodeState.value}
                            onChange={verifyCodeChangeHandler}
                            onBlur={validateVerifyCodeHandler}
                        />
                    </li>
                </ul>
                <FormButton style={{ marginTop: "20px" }} value={"Verify"} type="submit" />
            </form>

            <div>
                <ul className={classes["extraLinks-list"]}>
                    {(props.extraLinks || extraLinks).map((extraLink, index) => (
                        <li className={classes.listItem} key={index}>
                            <h4 className={classes.extraLink}>{extraLink.LinkText || ""}</h4> <h4 onClick={() => resendOTP(email)} className={classes.link}>{extraLink.LinkTextBold}</h4>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default FormCard;