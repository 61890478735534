import React, { useRef } from 'react'

import classes from './GridPaginationControls.module.css';

import FirstPage_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Page - First.svg";
import PrevPage_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Page - Back.svg";
import NextPage_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Page - Forward.svg";
import LastPage_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Page - Last.svg";
import DropDown_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Drop Down menu.svg";
import { Pagination } from '@tanstack/react-table';
import DataManager from '../../../hooks/DataManager';

const GridPaginationControls = ({ setPageIndex, setPageSize, previousPage, nextPage, pageIndex, PageSize, pageCount, PaginationOptions, resultCount, totalDevices, setTotalDevices }) => {

    const dataManager = new DataManager();
    let pageSizeOptions = PaginationOptions;
    
    // PageSize = dataManager.getItem('pageSize');
    // totalDevices = dataManager.getItem('totalDevices');
    // pageIndex = dataManager.getItem('pageIndex');
    // pageCount = dataManager.getItem('totalPages');
    
    if (pageSizeOptions === undefined) {
        pageSizeOptions = [20, 30, 40, 50]
    }

    if (pageCount < 1){
        pageCount = 1;
    }

    return (
        <div className={classes["paginationButtons-container"]} id="grid-pagination">

            <h3 className={classes["total-results"]}>Showing {totalDevices > 20 ? PageSize : totalDevices} of {totalDevices}</h3>

            <button className={classes["pagination_button"]} disabled={pageIndex == 1 ? true : false} onClick={() => setPageIndex(1)}>
                <img className={classes["button_image"]} src={FirstPage_Icon} onClick={() => setPageIndex(1)} />
            </button>

            <button className={classes["pagination_button"]} disabled={pageIndex == 1 ? true : false} onClick={() => previousPage()} >
                <img className={classes["button_image"]} src={PrevPage_Icon}></img>
            </button>

            <input className={classes["currentPage-box"]} type="number" value={pageIndex} onChange={(e) => setPageIndex(parseInt(e.target.value))} />
            <div>  <h4> {"of "}{pageCount}</h4></div>

            <button className={classes["pagination_button"]} disabled={pageIndex == pageCount ? true : false} onClick={() => nextPage()} >
                <img className={classes["button_image"]} src={NextPage_Icon}></img>
            </button>

            <button className={classes["pagination_button"]} disabled={pageIndex < pageCount ? false : true} onClick={() => setPageIndex(pageCount)}   >
                <img className={classes["button_image"]} src={LastPage_Icon} />
            </button>


            <div className={classes["pageSize-container"]} >
                <label htmlFor="pageSize">Show </label>
                <select className={classes["dropdown"]} id="pageSize" value={PageSize} onChange={(e) => setPageSize(e.target.value)}>
                    {pageSizeOptions.map((PageSize, index) => (
                        <option key={index} value={PageSize}>
                            {PageSize}
                        </option>
                    ))}
                </select>
            </div>


        </div>
    )
}

export default GridPaginationControls