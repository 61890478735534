import React, { useRef, useState, useEffect } from 'react'
import UseParseDateMonthYearTime from '../../../../hooks/UseParseDateMonthYearTime';
import UseParseYearMonthDate from '../../../../hooks/UseParseYearMonthDate';
import Pill_Widget from '../../../Widgets/Pill_Widget';

import classes from "./HolidayModeCardContentLayout.module.css"

import arrowIcon from "../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../Modal/Modal';
import TimeDatePicker from '../../../TimeDatePicker/TimeDatePicker';
import StatePicker from './StatePicker';
import ToggleInput from '../../../ToggleInput';
import Button1 from '../../../Button/Button1';
import { deleteHolidayModeAPI, editHolidayModeAPI } from '../../../../../Api Methods/Api';
import { UseConvertUTCToLocalTime } from '../../../../hooks/UseConvertUTCToLocalTime';


const HolidayModeCardContentLayout = ({ holdiayMode, OutPutsPinNames, smartLocks, fetchAutomations }) => {
  const isStatusActive = true;

  const client = useSelector(state => state.auth.client);
  const token = useSelector(state => state.auth.token);
  const DeviceUUID = useSelector(state => state.auth.DeviceUUID);

  const apiData = {
    token,
    client
  }

  //------------------------------- Modal Logic  ----------------------------------------------

  const ModalDialog = useRef();

  // call ref to clearStates method from child component ScheduledAction.
  const ClearStatesChildComponetRef = useRef();

  const HandleShowModal = () => {
    ModalDialog.current.open();
  }

  const [modalTitle, setModalTitle] = useState("Edit Holiday Mode");
  //--------------------------------------------------------------------------------------------

  const activeButtonRef = useRef();

  const [isHolidayModeActive, setIsHolidayModeActive] = useState(holdiayMode.active);

  const [latchPins, setLatchPins] = useState();


  console.log(holdiayMode)


  // Initialize state with current time
  const [holidayModeDetails, setHolidayModeDetails] = useState(holdiayMode);

  const HandleCloseModal = () => {
    console.log("here");
    ModalDialog.current.close();

    //reset model screen
    HandelResetModal();
  }

  // Handler for time change
  const HandleStartOn = (dateTime) => {
    setHolidayModeDetails(prevState => ({
      ...prevState, // Spread the previous state to keep other properties unchanged
      start: dateTime // Update only the start property
    }));
  };

  const HandleEndOn = (datetime) => {
    setHolidayModeDetails(prevState => ({
      ...prevState,
      end: datetime
    }));
  };

  const HandleOutputState = (pin, state) => {
    const states = holidayModeDetails.states
    states[pin] = state;

    setHolidayModeDetails(prevState => ({
      ...prevState, // Spread the previous state to keep other properties unchanged
      "states": states // Update only the start property
    }));
  }

  //updates the active property within holidayModeDetails
  useEffect(() => {
    setHolidayModeDetails(prevState => ({
      ...prevState, // Spread the previous state to keep other properties unchanged
      "active": isHolidayModeActive // Update only the start property
    }));
  }, [isHolidayModeActive])

  const dispatch = useDispatch();
  
  const HandleEditHolidayMode = async (event) => {
    event.preventDefault()

    if (holidayModeDetails.start != holidayModeDetails.end) {
      const response = await editHolidayModeAPI(apiData, holidayModeDetails, dispatch);
      if (response && response.success) {
        HandleCloseModal();
        fetchAutomations();
      }
    }

  }

  const HandleRemoveHolidayMode = async (event) => {
    const response = await deleteHolidayModeAPI(apiData, holidayModeDetails.id, dispatch);
    if (response && response.success) {
      HandleCloseModal();
      fetchAutomations();
    }
  }

  // reset model when Escape key is pressed
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        // Handle the Escape key press here
        HandleCloseModal();
      }
    };

    // Add event listener when component mounts
    document.addEventListener('keydown', handleKeyDown);


    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  console.log(holdiayMode);

  const HandelResetModal = () => {
    console.log("here");
    setHolidayModeDetails(holdiayMode)
  }


  useEffect(() => {

    //removed to match app availablePins
    // Filter for only active pins && pins with type of latch 
    // const availablePins = OutPutsPinNames.filter(outPut => outPut.active == '1' && outPut.type == 'LATCH');

    // get all outputs of type latch
    let LatchPins = OutPutsPinNames;
    if (smartLocks.length != 0) {
      LatchPins = OutPutsPinNames.filter(outPut =>
        smartLocks.some(smartLock => outPut.Pin != smartLock.outputPin)
      );
    }

    setLatchPins(LatchPins);

    // get all pulse pins
    const pulsePins = OutPutsPinNames.filter(outPut => outPut.type == 'PULSE');

    // set the pins in holidayDetails.states object to "pulse", if the are of type pulse
    if (pulsePins.length != 0) {
      pulsePins.map((pulseOutput) => {
        HandleOutputState(pulseOutput.Pin, "pulse");
      })
    }

    // get all pins that have dosmartlock assinged to it
    let smartLockPins = OutPutsPinNames.filter(outPut =>
      smartLocks.some(smartLock => outPut.Pin == smartLock.outputPin)
    );

    // for all pins that have a smart lock assinged to them, set the states of thoese pins to "lock" within holidaymode details.
    if (smartLockPins.length != 0) {
      smartLockPins.map((smartLockOutput) => {
        HandleOutputState(smartLockOutput.Pin, "lock");
      })
    }


  }, [])

  console.log(holidayModeDetails)
  return (
    <>
      <Modal ref={ModalDialog} title={modalTitle} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "700px", minHeight: "420.5px" }} modalLayoutStyle={{ alignItems: "center" }}>

        <div className={classes.editContainer}>
          <ToggleInput
            label={"Active"}
            ref={activeButtonRef}
            type="checkbox"
            isChecked={isHolidayModeActive}
            setIsChecked={setIsHolidayModeActive}
          />

          <TimeDatePicker handler={HandleStartOn} label={"Start at:"} preSelectedDateTime={holidayModeDetails.start} />
          <TimeDatePicker handler={HandleEndOn} label={"End at:"} preSelectedDateTime={holidayModeDetails.end} />

          <h3>What should the switch do during holiday mode?</h3>

          {latchPins && latchPins.map((outPut, index) => {
            if (outPut.type == "LATCH" || outPut.type == "VIRTUAL_LATCH"){
              return (
                <StatePicker index={index} pinText={outPut.pinName} outputPin={outPut.Pin} HandleOutputState={HandleOutputState} states={holidayModeDetails.states[outPut.Pin]} />
              )
            }
          })}

          <div className={classes.buttonContainer}>
            <Button1 onClick={HandleRemoveHolidayMode}>Remove</Button1>
            <Button1 onClick={HandleEditHolidayMode} >Save</Button1 >
          </div>
        </div >
      </Modal>

      <div className={classes.contentContainer} onClick={HandleShowModal}>
        <div className={classes.top}>

          <h3 className={classes.title}>Holiday Mode</h3>

          <Pill_Widget boolean={holdiayMode.active} PillColor_IfTrue={"var(--Active-color)"} PillColor_IfFalse={"var(--Inactive-color)"} text_IfTrue={"Active"} text_IfFalse={"Inactive"} className={classes.PillHeight} />
          {/* <div className={classes.statusIndicator} style={{backgroundColor: isStatusActive ? "yellow" : "grey"}}/> */}

        </div>
        <h4 className={classes.subtitle}> {UseParseDateMonthYearTime(holdiayMode.start)} - {UseParseDateMonthYearTime(holdiayMode.end)}</h4>
        <div className={classes["bottom-wrapper"]}>
          <h5 className={classes.subSubText}>Automations will not run during this period</h5>
          <div className={classes.arrowButton} onClick={HandleShowModal}>
            <img className={classes.Icon} src={arrowIcon} />
          </div>
        </div>
      </div>
    </>
  )
}

export default HolidayModeCardContentLayout