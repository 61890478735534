import React, { useEffect, useState } from 'react'
import Button1 from '../../../Button/Button1'

import classes from "./StatePicker.module.css"
const StatePicker = ({ pinText, outputPin, HandleOutputState, index, states }) => {

  const [activeButton, setActiveButton] = useState(states || "arm")

  useEffect(() => {
    setActiveButton(states)
  }, [states])
  

  const Handler = (buttonText) => {
    HandleOutputState(outputPin, buttonText);
    setActiveButton(buttonText);
  }

  return (
    <div className={classes.container} key={index} >

      <h3>{pinText}</h3>
      <div className={classes.buttonContainer}>
        <Button1 propsclassName={activeButton === "arm" && classes.activeButton} onClick={() => Handler("arm")}>Arm</Button1>
        <Button1 propsclassName={activeButton === "disarm" && classes.activeButton} onClick={() => Handler("disarm")} >Disarm</Button1>
        <Button1 propsclassName={activeButton === "ignore" && classes.activeButton} onClick={() => Handler("ignore")}>Ignore</Button1>
      </div>
    </div>
  )
}

export default StatePicker