import React, { useState, useEffect } from 'react'

import classes from './StatusIndicatorCard.module.css'
const StatusIndicatorCard = (props) => {
  const [toggleState, setToggleState] = useState(props.status);

  useEffect(() => {
    setToggleState(props.status);
  }, [props.status]);

  // console.log(toggleState);

  let title = "";
  let statusClass = "";

  if (toggleState === 0) {
    title = "Inactive";
    statusClass = classes.inactive;
  } else if (toggleState === 1) {
    title = "Active";
    statusClass = classes.active;
  } else if (toggleState === 2) {
    title = "Partially Active";
    statusClass = classes.partiallyActive;
  }

  return (
    <div className={`${classes.StatusIndicatorCard} ${statusClass}`}>
      {title}
    </div>

  )
}

export default StatusIndicatorCard