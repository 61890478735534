import React from 'react';

import classes from "./SearchBar.module.css";

const SearchBar = (props) => {
    const { placeholder, onChange, value} = props;

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      };
    
    return (
        <input className={classes.searchBar} placeholder={placeholder} value={value} onKeyPress={handleKeyPress} onChange={onChange} onFocus={props.handleFocus} onBlur={props.handleBlur} spellCheck={false} />
    )
}

export default SearchBar