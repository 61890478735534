import React from 'react'

import classes from "./GroupsGrid.module.css";
import Card from './Card';
import GroupCard from '../UI/Cards/GroupCard';

const GroupsGrid = () => {

    const Automations = [
        {
            GroupStatus: 1,
            NumberOfUsers: 8,
            Roles: [ 1, 2 ],
            groupType: 'Basic',
            groupUserInitials: [ 'Ag', 'Rb' ],
            id: 1,
            title: 'Showroom'
          },
          {
            GroupStatus: 1,
            NumberOfUsers: 4,
            Roles: [ 1, 2 ],
            groupType: 'Basic',
            groupUserInitials: [ 'BF', 'NR' ],
            id: 1,
            title: 'Garage'
          },
          {
            GroupStatus: 0,
            NumberOfUsers: 3,
            Roles: [ 1, 2 ],
            groupType: 'Basic',
            groupUserInitials: [ 'ND', 'SP' ],
            id: 1,
            title: 'Forecourt'
          },
    ]

    return (
        <ul className={classes["grid-ul"]}>
            {Array.isArray(Automations) && Automations.map((cardData, index) => (
                <li className={classes["grid-li"]} key={index}>
                    {/* Key passed to act as index for each card, to make it so when a card is changed it replays the fade from top animation :: key={index + pageIndex * pageSize}  */}

                    <Card className={classes.card} index={index} color={cardData.type}>
                        
                        <GroupCard cardData={cardData} />
                    </Card>
                </li>
            ))}
        </ul>
    )
}

export default GroupsGrid