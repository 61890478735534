import React from 'react'
import { resendOTP } from '../Api';

const postRequestNoAuth = async (uri, data, headers) => {
    const response = await fetch(
        uri, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
        },
    });

    if (!response.ok) {
        console.error(response);
        throw new Error('Fetching failed.');
    }

    const responseData = await response.json();
    return responseData;
};

export default postRequestNoAuth
