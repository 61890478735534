import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, redirect, useLoaderData } from "react-router-dom";
import { getTokenDuration } from "../../../auth";
import NotificationCard from "../../../components/UI/Modal/NotifcationCard/NotificationCard";
import { authActions } from "../../../store/auth-Slice";

function AuthRootLayout() {
    const authCredentials = useLoaderData();
    // const token = authCredentials.token;

    // useEffect(() => {

    //     if (!token) {
    //         return null;
    //     }

    //     if (token === 'EXPIRED') {
    //         redirect("/logout")
    //         return;
    //     }

    //     const tokenDuration = getTokenDuration();

    //     console.log(tokenDuration);

    //     setTimeout(() => {
    //         redirect("/logout")
    //     }, tokenDuration)

    //     return () => {

    //     }
    // }, [token])


    const notification = useSelector(state => state.ui.notification);
    const toggleNotification = useSelector(state => state.ui.toggleNotification);
    
  

    return <>

        <main>
            {toggleNotification && <NotificationCard status={notification.status} title={notification.title} message={notification.message} />}
            <Outlet />
        </main>
    </>
}

export default AuthRootLayout;   