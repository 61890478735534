import React from 'react'

import classes from "./Input1.module.css";

const Input1 = React.forwardRef((props, ref) => {
    return (
        <input
            className={classes.input}
            placeholder={props.placeholder}
            ref={ref}
            id={props.id}
            type={props.type}
            isValid={props.isValid}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
        />
    );
});

export default Input1