function useSetCookie() {
    return (name, value, hours) => {
        const date = new Date();
        date.setTime(date.getTime() + ((hours || 12) * 60 * 60 * 1000)); // Expiry set to 12 hours from now
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };
}

export default useSetCookie;
