export const UseConvertDateTimeToUTC = (dateValue, timeValue) => {

    //splits the time and date into an array of substrings, the map(number) convers each substring to intergers
    const [year, month, day] = dateValue.split('-').map(Number);
    const [hours, minutes] = timeValue.split(':').map(Number);

    // Create a new Date object based on the local date and time (the date object will keep track of local time&date when converting to utc, so in cases where the time has been set from 00:00 to 23:00 the date will be set back on day as well)
    const localDateTime = new Date(year, month - 1, day, hours, minutes);

    // Convert the local Date object to UTC
    const utcYear = localDateTime.getUTCFullYear();
    const utcMonth = String(localDateTime.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, add 1
    const utcDay = String(localDateTime.getUTCDate()).padStart(2, '0');
    const utcHours = String(localDateTime.getUTCHours()).padStart(2, '0');
    const utcMinutes = String(localDateTime.getUTCMinutes()).padStart(2, '0');
    const utcSeconds = String(localDateTime.getUTCSeconds()).padStart(2, '0');

    // Format the date and time as 'YYYY-MM-DD HH:MM:SS'
    const formattedUTC = `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}`;

    return formattedUTC;
}