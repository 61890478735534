import React, { Suspense, useCallback, useEffect, useState } from 'react'
import AutomationsGrid from '../../../GridList/AutomationsGrid'
import CreateAutomation from './EditAutomations/CreateAutomation';
import Card from "../../../GridList/Card";
import CreateRule from './CreateRule/CreateRule';
import ScheduledAction from './EditAutomations/ScheduledAction';
import CreateSchedule from './EditAutomations/CreateSchedule';
import { getHolidaysModesAPI, getInputRules, getSchedules } from '../../../../Api Methods/Api';
import { useSelector } from 'react-redux';
import { Await } from 'react-router-dom';
import Oval from 'react-loading-icons/dist/esm/components/oval';

import classes from "./Automations.module.css"
import { VerifyHandler } from '../../../../store/auth-actions';
import CreateHolidayMode from './HolidayMode/CreateHolidayMode';

const Automations = ({ OutPutsPinNames, InputsPinNames, deviceData }) => {
    const numInput = deviceData.singleDeviceSettingData.numInput

    const smartLocks = deviceData.singleDeviceSettingData.iglooLocks;

    const [IFTTTRules, setIFTTTRules] = useState(null);
    const [schedules, setSchedules] = useState(null);
    const [holidaymode, setHolidayMode] = useState(null);


    console.log(holidaymode)

    let token = useSelector(state => state.auth.token);
    let client = useSelector(state => state.auth.client);
    let DeviceUUID = useSelector(state => state.auth.DeviceUUID);

    const apiData = {
        token,
        client
    }

    // fetchAutomations is called when an automation is Created, Edited or Deleted.
    const fetchAutomations = () => {
        console.log("here");
        const getIFTTTRules = async () => {
            const responseData = getInputRules(apiData, DeviceUUID)
            console.log(responseData)
            setIFTTTRules(responseData);
        }

        const getSchedulesAndReminders = async () => {
            const responseData = getSchedules(apiData, DeviceUUID)
            setSchedules(responseData);
        }


        const getHolidaysMode = async => {
            const responseData = getHolidaysModesAPI(apiData, DeviceUUID)
            setHolidayMode(responseData);
        }


        getIFTTTRules();
        getSchedulesAndReminders();
        getHolidaysMode();
    }

    // Fetch data on mount
    useEffect(() => {
        fetchAutomations();
    }, []);


    const [showAutomation, setShowAutomation] = useState(null);
    //user selects between creating a sheduledAction, reminder or a rule - 0,1 or 2
    const HandleSelectAutomation = (AutomationID) => {
        setShowAutomation(AutomationID);
    }

    //--------------------   first index is outputs, second index is inputs, third is device details--------------------------------
    const DeviceDataArray = Object.values(deviceData);

    let allListOptionsArray;

    if (numInput != 0) {
        allListOptionsArray = [...OutPutsPinNames, ...InputsPinNames];
    } else {
        allListOptionsArray = [...OutPutsPinNames];
    }

    //--------------------------------------------------------------------------------------------------------------------------------

    //use to pass the rule's pins that haven't been assigned to CreateRule component
    const [remainingrules, setRemainRules] = useState([]);

    const updateRemainRules = (rules) => {
        setRemainRules(rules)
    }
    return (
        <div>
            <Suspense fallback={
                <div className={classes["loadingSpinner-container"]}>
                    <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                </div>
            }>
                <Await resolve={Promise.all([IFTTTRules, schedules, holidaymode])}>
                    {([loadedSiteRules, loadedSiteSchedules, loadedHolidaymode]) =>
                        <AutomationsGrid DeviceRules={loadedSiteRules} siteSchedules={loadedSiteSchedules} siteHolidayModes={loadedHolidaymode} OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} allListOptionsArray={allListOptionsArray} HandleSelectAutomation={HandleSelectAutomation} fetchAutomations={fetchAutomations} numInput={numInput} smartLocks={smartLocks} updateRemainRules={updateRemainRules} />
                    }
                </Await>
            </Suspense>
            

            {showAutomation == 2 && <CreateRule allListOptionsArray={remainingrules || allListOptionsArray} OutPutsPinNames={OutPutsPinNames} setShowAutomation={setShowAutomation} fetchAutomations={fetchAutomations} />}
            {(showAutomation == 0 || showAutomation == 1) && < CreateSchedule OutPutOptions={OutPutsPinNames} isReminder={showAutomation} setShowAutomation={setShowAutomation} fetchAutomations={fetchAutomations} />}

            {showAutomation === 3 && <CreateHolidayMode OutPutsPinNames={OutPutsPinNames} setShowAutomation={setShowAutomation} smartLocks={smartLocks} fetchAutomations={fetchAutomations} />}
        </div>
    )
}

export default Automations