import React, { useState, useReducer, useRef, useEffect, useImperativeHandle, forwardRef } from "react";


import CustomDropDown from "../CustomDropDown/CustomDropDownWithIcon";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from './InviteUserForm.module.css';

import ToggleInput from "../UI/ToggleInput";
import Input from "../CustomDropDown/Input/Input";
import Button1 from "../UI/Button/Button1";
import { copyIPIOSettings, copyUsersToAnotherDevice, inviteUser } from "../../Api Methods/Api";

const extraLinks = [
    {
        LinkTextBold: "Subscribe to get release inputs",
    },
]

const input1Reducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.length > 0 };
    }
    //otherwise 
    return { value: '', isValid: false };
}


const input2Reducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.length > 0 };
    }
    //otherwise 
    return { value: '', isValid: false };
}




const CopyUserToAnotherDeviceForm = ({ placeholder, label, submitButtonText, processFunction, processFunctionParamaters, HandleCloseModal, dispatch }) => {

    const [formIsValid, setFormIsValid] = useState(false);

    const [input1State, dispatchInput1] = useReducer(input1Reducer, {
        value: '',
        isValid: true
    });

    const [input2State, dispatchInput2] = useReducer(input2Reducer, {
        value: '',
        isValid: null
    });


    const input1Ref = useRef();
    const input2Ref = useRef();
    let { isValid: input1IsValid } = input1State;
    const { value: input1Value } = input1State
    const { isValid: input2IsValid } = input2State;

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (input1Value === "") {
                input1IsValid = false;

            } else {
                setFormIsValid(input1IsValid)
            }
        }, 500);

        return () => {
            // console.log('CLEANUP');
            clearTimeout(identifier);
        };
    }, [input1IsValid]);

    const input1ChangeHandler = (event) => {
        dispatchInput1({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    const validateInput1Handler = () => {
        dispatchInput1({ type: 'INPUT_BLUR' });
    }

    const input2ChangeHandler = (event) => {
        dispatchInput2({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    const validateInput2Handler = () => {
        dispatchInput2({ type: 'INPUT_BLUR' });
    }

    const onClearHandler = () => {
        dispatchInput1({ type: 'INPUT_CLEAR', val: "" })
        dispatchInput2({ type: 'INPUT_CLEAR', val: "" })
    }

    const submitHandler = (event, processFunctionParamaters) => {
        event.preventDefault();
        if (formIsValid) {
            const toUipioid = input1State.value;
            const deviceName = input2State.value;


            copyUsersToAnotherDevice(processFunctionParamaters, deviceName, toUipioid, dispatch)

            onClearHandler();
            HandleCloseModal(false);

            return;

        } else if (!input1IsValid) {
            input1Ref.current.focus();
        } else if (!input2IsValid) {
            input2Ref.current.focus();
        }

    };

    return (
        <form onSubmit={(event) => submitHandler(event, processFunctionParamaters)} className={classes["container"]} method="dialog" >
            <ul className={classes["inputs-list"]}>
                <div className={classes.label}>{label}</div>
                <li className={classes["input-container"]}>

                    <Input
                        className={classes.input}
                        placeholder={"XDFGTY"}
                        ref={input1Ref}
                        id="Input1"
                        type={"text"}
                        isValid={input1State.isValid}
                        value={input1State.value}
                        onChange={input1ChangeHandler}
                        onBlur={validateInput1Handler}
                    />
                    {!input1IsValid && <h3 className={classes.errorText}>No UIPIOID Entered</h3>}

                    <Input
                        className={classes.input}
                        placeholder={"Site Name"}
                        ref={input2Ref}
                        id="Input2"
                        type={"text"}
                        isValid={input2State.isValid}
                        value={input2State.value}
                        onChange={input2ChangeHandler}
                        // onBlur={validateInput2Handler}
                    />
                    {/* {!input2IsValid && <h2>No UIPIOID Entered</h2>} */}
                </li>


                <div className={classes["button-container"]}>
                    <Button1 className={classes.submitButton} type="button" onClick={onClearHandler}>Clear</Button1>
                    <Button1 className={classes.submitButton} type="submit">{submitButtonText || "Submit"}</Button1>
                </div>
            </ul>
        </form>
    )
}

export default CopyUserToAnotherDeviceForm;

