import React from 'react'

import classes from "./ControlsBarButton.module.css";

const ControlsBarButton = (props) => {

    const handleActiveButtonOnClick = (buttonText) => {
        props.onClick(buttonText)
        props.setActiveButton(buttonText)
    }

    return (
        <button
            type={props.type || 'button'}
            className={props.className || ` ${classes["button-container"]} ${props.activeButton === props.title && classes.active}`}
            onClick={() => handleActiveButtonOnClick(props.title)}
            disabled={props.disabled}
            onMouseEnter={props.handleMouseEnter}
            onMouseLeave={props.handleMouseLeave}
        // style={{ backgroundColor: props.activeButton === props.title ? classes.active : props.ButtonColor, color: props.textColor }}
        >
            <img className={`${classes.img} ${props.activeButton === props.title && classes.activeImg}`} src={props.imagePath} ></img>
            {props.title || ""}
        </button>
    )
}

export default ControlsBarButton