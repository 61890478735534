import React, { Fragment } from "react";
import ComponentCard from "../../../../Cards/ComponentCard/ComponentCard";
import PinInputAdd from "../../../PinInput/InputPinV2/Input/PinInput-Add";
import OutputAdd from "../OutPut/OutputPin-Add";
import PinOutput from "../OutPut/PinOutput"
import PinOutputPulse from "../OutPut/PinOutputPulse";
import PinOutputPulseSocket from "../OutPut/PinOutputPulseSocket";

import classes from "./PinOutputList.module.css";

const PinOutputList = ({ OutputPins, polarity, SmartLocks, firmware }) => {
    console.log(firmware)
    return (
        <ComponentCard id="OutPutPinListCard" className={classes["pin-switches"]}>
            {OutputPins.map((outPutPin, index) => {
                const SmartLock = SmartLocks.find(Lock => Lock.outputPin == outPutPin.Pin);
                return (
                    outPutPin.type == "LATCH" || outPutPin.type == "VIRTUAL_LATCH" ? <PinOutput key={index} OutPutPin={outPutPin} polarity={polarity} SmartLock={SmartLock != undefined ? SmartLock : null} firmware={firmware} /> :
                        <PinOutputPulseSocket key={index} OutPutPin={outPutPin} polarity={polarity} SmartLock={SmartLock != undefined ? SmartLock : null} />
                )
            })}
        </ComponentCard>
    )

}

export default PinOutputList;