import React from "react";
import classes from './Card.module.css';

const Card = (props) => {
    return (
        <div className={`${classes.card} ${props.secondaryClassName}`} onClick={props.onClick}>
         {props.children}
        </div>

    );
}

export default Card;