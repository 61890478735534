import React, { useEffect, useReducer, useRef, useState } from 'react'
import Modal from '../../../../../Modal/Modal';



import { useDispatch, useSelector } from 'react-redux';

import classes from "./SigfoxModalForm.module.css"
import Input from '../../../../../CustomDropDown/Input/Input';
import Button1 from '../../../../Button/Button1';
import { addCamectAPI, addSigfoxAPI, updateCamectAPI } from '../../../../../../Api Methods/Api';
import { checkAuthLoader } from '../../../../../../auth';
import Button2 from '../../../../Button/Button2';

const SigfoxIDReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: null };
    }
    //otherwise 
    return { value: '', isValid: false };
}


const SigfoxModalForm = ({ setShowVirtualSource, inputs, virtualType, fetchVirtualSources }) => {

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);


    const apiData = {
        token,
        client
    }

    const [modalComponent, setModalComponent] = useState(null);
    const [modalPageIndex, setModalPageIndex] = useState(0);

    const [pinSelected, setPinSelected] = useState(null)

    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        HandelResetModal();
    }
    //-------------------------------------------------------------------------------------------------

    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandelResetModal();
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);


        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const [formIsValid, setFormIsValid] = useState(false);

    const [sigFoxIDState, dispatchSigFoxIDState] = useReducer(SigfoxIDReducer, {
        value: '',
        isValid: null
    });

    const sigfoxIDInputRef = useRef();

    const { isValid: sigfoxIDIsValid } = sigFoxIDState;

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(sigfoxIDIsValid)
        }, 500);

        return () => {
            clearTimeout(identifier);
        };
    }, [sigfoxIDIsValid]);

    const sigfoxIDChangeHandler = (event) => {
        dispatchSigFoxIDState({ type: 'USER_INPUT', val: event.target.value });
    }

    const validateSigfoxIDHandler = () => {
        dispatchSigFoxIDState({ type: 'INPUT_BLUR' });
    }


    const HandelResetModal = () => {
        setModalPageIndex(null);
        setShowVirtualSource(null);
    }

    const onClearHandler = () => {
        dispatchSigFoxIDState({ type: 'INPUT_CLEAR', val: "" })
    }

    const dispatch = useDispatch();
    //10.250.5.69
    //tech.resure

    const submitHandler = async (event) => {
        event.preventDefault();

        const sensorDetails = {
            "device": sigFoxIDState.value,
            "pac": "",
            "uuid": DeviceUUID,
            "virtualInput": pinSelected.toString(),
            "virtualType": virtualType,
        }
        const responseData = await addSigfoxAPI(apiData, sensorDetails, dispatch)
        if (responseData && responseData.success) {
            HandleCloseModal();
            fetchVirtualSources();
        }
    };


    const HandlePinSelected = (pin) => {
        setPinSelected(pin);
        setModalPageIndex(1);
    }

    useEffect(() => {
        switch (modalPageIndex) {
            case 0:
                setModalComponent(
                    <form onSubmit={submitHandler} className={classes["container"]} method="dialog">
                        <ul className={classes.PinsList}>
                            {inputs.map((input) => {
                                return (
                                    <li key={input.Pin}>
                                        <Button2 type={"button"} onClick={() => HandlePinSelected(input.Pin)}>
                                            {input.pinName}
                                        </Button2>
                                    </li>
                                )
                            })}
                        </ul>
                        {/* <Button1 propsclassName={classes.submitButton} type="submit">{title == "Edit" ? "Update" : "Save"}</Button1> */}
                    </form>
                )

                HandleShowModal();
                break;
            case 1:
                setModalComponent(
                    <form onSubmit={submitHandler} className={classes["container"]} method="dialog">
                        <ul className={classes["inputs-list"]}>
                            <li className={classes["input-container"]}>
                                <label className={classes.subTitle}>Which switch/input would you like to link to?</label>
                                <Input
                                    className={classes.input}
                                    placeholder={"Sigfox ID"}
                                    ref={sigfoxIDInputRef}
                                    id="CamectIP"
                                    type={"text"}
                                    isValid={sigFoxIDState.isValid}
                                    value={sigFoxIDState.value}
                                    onChange={sigfoxIDChangeHandler}
                                    onBlur={validateSigfoxIDHandler}
                                />

                            </li>
                        </ul>

                        <Button1 propsclassName={classes.submitButton} type="submit">{"Save"}</Button1>
                    </form>
                )
                HandleShowModal();
                break;
        }
    }, [modalPageIndex, sigFoxIDState]); // Empty dependency array ensures it runs only once after initial render



    const CreateSchedule = async (body) => {

        // const response = await createScheduleApi(apiData, body, dispatch);
        // if (response && response.success) {
        //     HandleCloseModal();
        //     fetchAutomations();
        // }

    }

    return (
        <>
            <Modal ref={ModalDialog} title={"Add Sigfox"} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>
        </>
    )
}

export default SigfoxModalForm;