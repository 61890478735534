import React, {useContext} from "react"; 
import classes from './ComponentCard.module.css';

const ComponentCard = (props) => {
   
    return (
            <div className={`${classes.card} ${props.className}`}>
                {props.children}
            </div>
        

        
    );
}

export default ComponentCard;