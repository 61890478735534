import React from 'react'
import Button1 from '../../../Button/Button1';

import classes from "./ListOptions.module.css";
//<ListOption optionText={Option.OptionText} buttonText={Option.buttonText} array={associatedArray}/>

const ListOption = ({ optionText, buttonText, array, onClick, propsClassName }) => {
    return (
        <div className={`${classes.listOption} ${propsClassName}`}>
            {optionText && optionText !== "" && <h4  >{optionText}</h4>}
            {buttonText && <Button1 onClick={() => onClick(array)}>{/* Add a unique key for each list item */}
                {"[" + buttonText + "]"}
            </Button1>}
        </div>
    )
}

export default ListOption