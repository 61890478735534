import React, { useState, useEffect } from 'react';

import classes from "./UserIcon.module.css";

const UserIcon = (props) => {
    return (
       <div className={`${classes[props.className]} ${classes['userIcon-div']}`}>
            <h2>{props.userInitials}</h2>
        </div>
    )
}

export default UserIcon